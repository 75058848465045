import { Button, Modal } from "react-bootstrap";
import ERROR_IMG from "../assets/images/close.png";
import "./error.modal.scss";

const ErrorModal = ({isOpen, handleClose}) => {



    return (
        <>
         <Modal show={isOpen} onHide={handleClose} className="error-modal">
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <div className="error-modal-control">
                    <img src={ERROR_IMG} className="error-modal-img"></img>
                    <span className="error-modal-text">เกิดข้อผิดพลาดในระบบ</span>
                    <Button className="error-modal-button" onClick={handleClose}>ปิด</Button>
                </div>
            </Modal.Body>

           </Modal>
        </>
    )
}

export default ErrorModal;