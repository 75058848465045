import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import "./audience-list.scss";
import { useEffect, useState } from "react";
import CreateAudienceComponent from "../create-audience";
import CRMServices from "../../../../../services/crm.service";
import ProfileSCModel from "../../../../../model/profile-sc.model";
import AudienceListModel, { Audience } from "../../../../../model/audience/audience-list.model";
import templateConfig from "../../../../../template-config";
import { StyledTableCell, StyledTableRow } from "../../../../../utils/theme";
import AudienceImportComponent from "../import/audience-import";


const AudienceListComponent = ({ profileModel }) => {

    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const [audienceList, setAudienceList] = useState<AudienceListModel>();
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
    const [idEdit, setIdEdit] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenImport, setIsOpenImport] = useState(false);


    useEffect(() => {
        if (profileMod) {
            getAudienceList();
        }
    }, [profileMod])

    const handleCloseImport = () => {
        setIsOpenImport(false);
        setIsOpenCreateModal(false);
        setIsEdit(false);
        setIdEdit("");
        getAudienceList();
    }
    const handleCloseModalCreate = () => {
        setIsOpenCreateModal(false);
        setIsEdit(false);
        setIdEdit("");
        getAudienceList();
    }

    const createAudienceModal = () => {
        setIsOpenCreateModal(true);
    }

    const getAudienceList = () => {
        if (profileMod) {
            CRMServices().getAudienceAll().then((res: AudienceListModel) => {
                console.log(res);
                if (res.audiences) {
                    setAudienceList(res)
                    setIsLoading(false);
                }

            })
        }


    }

    const clickEdit = (id) => {
        setIdEdit(id);
        setIsEdit(true);
        setIsOpenCreateModal(true);
    }

    const importAudience = () => {
        setIsOpenImport(true);
    }

    const clickDelete = (id) => {
        CRMServices().deleteAudience(id).then((res) => {

            if (res) {
                getAudienceList();
            }
        })

    }
    return (

        <>
            <div className="audience-list-control">
                <div className="audience-list-create-control">
                    <Button className="audience-list-button-create" onClick={importAudience}>+ Import Audience</Button>
                    <Button className="audience-list-button-create" onClick={createAudienceModal}>+ สร้าง Audience</Button>
                </div>


                {
                    isLoading &&
                    <div>

                        <div className="audience-list-loading-control">
                            <div className="audience-list-loading-progress-control">
                                <CircularProgress className="audience-list-loading-progress"></CircularProgress>
                                <span className="audience-list-loading-text">กำลังโหลด</span>
                            </div>
                        </div>
                    </div>
                }
                {

                    (audienceList && !isLoading && audienceList.audiences.length > 0) ?
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left" width='30%'>Name</StyledTableCell>
                                        <StyledTableCell align="left" width='20%'>ID</StyledTableCell>
                                        <StyledTableCell align="center" width='20%'>Approx. Volume</StyledTableCell>
                                        <StyledTableCell align="left" width='20%'>Created at</StyledTableCell>
                                        <StyledTableCell align="left" width='10%'></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {audienceList.audiences.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at))).map((row: Audience, index: number) => (



                                        <StyledTableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell component="th" scope="row">
                                                {row.audience_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{row.audience_uuid}</StyledTableCell>
                                            <StyledTableCell align="center">{row.audience_approx_count}</StyledTableCell>
                                            <StyledTableCell align="left">{new Date(row.created_at).toDateString() + " : " + new Date(row.created_at).toLocaleTimeString()}</StyledTableCell>
                                            <StyledTableCell align="left" className="d-flex flex-column">
                                            <Button className="audience-list-button-view" onClick={() => clickEdit(row.audience_uuid)}>Edit</Button>
                                            <Button className="audience-list-button-view" onClick={() => clickDelete(row.audience_uuid)}>Delete</Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : <></>
                }
                {
                    isOpenCreateModal &&
                    <CreateAudienceComponent isOpen={isOpenCreateModal} isEdit={isEdit} id={idEdit} handleClose={handleCloseModalCreate}></CreateAudienceComponent>
                }
                {
                    isOpenImport &&
                    <AudienceImportComponent isOpen={isOpenImport} handleClose={handleCloseImport}></AudienceImportComponent>
                }

            </div>
        </>
    )
}

export default AudienceListComponent;