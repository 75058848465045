import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { OauthModel } from "../model/oauth.model";
import storage from "../storage";
import TokenService from "./token.service";



const instanceResume = axios.create({
  baseURL: config.chat_gpt.upload_resume.base_url,
  headers: {
    "x-api-key" : config.chat_gpt.upload_resume.x_api_key
  },
})

const instanceGPTRefresh = axios.create({
  baseURL: config.authen.api.host_url,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

instanceResume.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["scoutout-authorization"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    alert("กรุณา Login ใหม่");
    window.open('/authen')
    return Promise.reject(error);
  }
);

instanceResume.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== config.authen.api.token && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instanceGPTRefresh.post(config.authen.api.token, {
            grant_type: 'refresh_token',
            refresh_token: TokenService.getLocalRefreshToken(),
          });
          const token = rs.data as OauthModel
          TokenService.updateLocalAccessToken(token.access_token);

          return instanceResume(originalConfig);
        } catch (_error) {
        
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instanceResume;