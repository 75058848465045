import { Button, Form } from "react-bootstrap";
import languageConfig from "../../../language-config";
import "../../features/features.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import JDSuggestionModel from "../../../model/jd-suggestion.model";
import { useContext, useEffect, useRef, useState } from "react";
import { ProviderContext } from "../../../context/provider.context";
import ProfileSCModel from "../../../model/profile-sc.model";
import ConvertResultServices from "../../../services/convert-result.service";
import UtilityService from "../../../utils/utilities";
import ResultClinicComponent from "../result-clinic/result-clinic";
import TRICK_JSON from "../../../assets/json/trick-feature.json";
import EXAMPLE_JSON from "../../../assets/json/example/interview-question.json";
import { InterviewQuestionInputModel, InterviewQuestionsModel } from "../../../model/interview-question/interview-question.model";
import LoadingComponent from "../loading/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import templateConfig from "../../../template-config";
import GPTService from "../../../services/gpt.service";
const InterviewQuestionComponent = ({ profileModel, submitWithOutCredit, authenError }: any) => {
    const { creditModel, setCreditModel }: any = useContext(ProviderContext)
    const [jdSuggestion, setJdSuggestion] = useState<JDSuggestionModel[]>([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [jobTitle, setJobTitle] = useState("");
    const [autocompleteList, setAutocompleteList] = useState<string[]>([]);
    const [jobExperience, setJobExperience] = useState("");
    const [jobSkill, setJobSkill] = useState("");
    const [language, setLanguage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(EXAMPLE_JSON["result"]);
    const [isValidate, setIsValidate] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const { setResultRawString }: any = useContext(ProviderContext)
    const [isShare, setIsShare] = useState(false);
    const [jobDescription, setJobDescription] = useState("");
    const [questionAmount, setQuestionAmount] = useState("");
    const [jobSalary, setJobSalary] = useState("");
    const [isExample, setIsExample] = useState(true);
    const resetALL = () => {
        setJobSkill("");
        setJobTitle("");
        setJobExperience("");
        setQuestionAmount("");
        setJobSalary("");
        setLanguage("");
        setResult("");
        setIsValidate(false);

    }
    const textFieldRefEXP = useRef<HTMLInputElement>(null);
    const textFieldRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (textFieldRef && textFieldRef.current) {
            const handleWheel = e => e.preventDefault();
            textFieldRef.current.addEventListener("wheel", handleWheel);

            return () => {
                if (textFieldRef && textFieldRef.current) {
                    textFieldRef.current.removeEventListener("wheel", handleWheel);
                }
            };
        }

    }, [textFieldRef]);
    useEffect(() => {
        if (textFieldRefEXP && textFieldRefEXP.current) {
            const handleWheel = e => e.preventDefault();
            textFieldRefEXP.current.addEventListener("wheel", handleWheel);

            return () => {
                if (textFieldRefEXP && textFieldRefEXP.current) {
                    textFieldRefEXP.current.removeEventListener("wheel", handleWheel);
                }
            };
        }

    }, [textFieldRefEXP]);
    const handleJobRoleChange = (event: any) => {
        setIsSubmiting(false);

        if (event) {
            try {
                if (event[0]["customOption"] != undefined) {
                    setJobTitle(event[0]["label"]);
                    console.log(event[0]["label"]);
                } else {
                    if (jdSuggestion) {
                        jdSuggestion.map((item: JDSuggestionModel, index: number) => {
                            if (item.job_title === event[0]) {
                                setJobTitle(item.job_title);
                                console.log(item.job_title);
                            }
                        })
                    }
                }
            } catch {

            }

        }
    }

    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel)
        }
    }, [profileModel])

    useEffect(() => {
        getJDSuggestion();
    }, [])

    const getJDSuggestion = () => {
        GPTService.getJDSuggestion().then((res: any) => {
            if (res) {
                const resData = res.data as JDSuggestionModel[];
                setJdSuggestion(resData);
                const autoComp: string[] = [];
                resData.map((item: JDSuggestionModel, index: number) => {
                    autoComp.push(item.job_title);
                })
                setAutocompleteList(autoComp)
            }

        })
    }

    const onTextChange = (event: any, setTitle: any) => {
        const jt: string = event.target.value;
        setTitle(jt);
    }
    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value)
    }

    const submit = () => {
        setIsSubmiting(true);
        if (jobTitle && jobExperience && Number(jobExperience) >= 0 && jobSalary && Number(jobSalary) >= 0 && jobDescription && language) {
            setIsLoading(true);
            setResult("")
            setIsValidate(true);
            const objectResult: InterviewQuestionInputModel = {
                job_title: jobTitle,
                job_description: jobDescription,
                job_experience: jobExperience,
                job_salary: jobSalary,
                question_language: language,
                question_amount: Number(questionAmount),
                uuid: profileMod!.uuid
            }

            GPTService.postCustom(objectResult, "/question").then((res) => {
                if (res) {
                    if (result == "500") {
                        setResult("500")
                        setIsSubmiting(false);
                    } else if (res.status == 401) {
                        authenError();
                    }else{
                        const answerModel: InterviewQuestionsModel = res.data;
                        ConvertResultServices().InterviewGenerator(answerModel, objectResult.job_title).then((result) => {
                            if (result) {
                                UtilityService().clickSendEvent("generate_interview_question" + objectResult.job_title, "click")
                                setIsLoading(false);
                                setIsShare(true);
                                setResultRawString(result);
                                setResult(result);
                                GPTService.getCredit().then((res3) => {
    
                                    if(res3){
                                        setCreditModel(res3.data)
                                    }
                                })
                            }
                            setIsSubmiting(false);
                        });
                    }
                }
            }).catch((err) => {
                setResult("500")
                setIsSubmiting(false);
            })

        }
    }


    return (

        <>
            <div className="feature-control">


                <div className="feature-form-control">
                    <div className="feature-form-left-control">
                        <h1 className="feature-main-title">{languageConfig.content.interview_question.title}</h1>
                        <span className="feature-main-desc">{languageConfig.content.interview_question.description}</span>
                        <div className="feature-credit-usage-control">
                            <FontAwesomeIcon icon={faCoins} className="feature-credit-usage-icon"></FontAwesomeIcon>
                            <span className="feature-credit-usage-text">ใช้เครดิต:
                                <span className="feature-credit-usage-text-bold-line-through">{templateConfig.credit_usage.ai_tools.standard} เครดิต</span>
                                <span className="feature-credit-usage-text-bold">{templateConfig.credit_usage.ai_tools.discount} เครดิต</span></span>
                        </div>
                        {
                            isValidate ?

                                <>
                                    <div className="dashboard-form-item-submit-control">
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Job Title (ตำแหน่งงาน)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobTitle}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Job Description (รายละเอียดงาน)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobDescription}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Job Experience (ประสบการณ์ทำงานในตำแหน่งนี้)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobExperience}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Job Salary (เงินเดือนของตำแหน่งงานนี้)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {jobSalary}
                                            </div>
                                        </div>
                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                Questions Amount (จำนวนคำถาม)
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {questionAmount}
                                            </div>
                                        </div>

                                        <div className="dashboard-form-item-submit-item">
                                            <div className="dashboard-form-item-submit-title">
                                                ภาษาที่ต้องการสร้าง ชุดคำถามสำหรับสัมภาษณ์
                                            </div>
                                            <div className="dashboard-form-item-submit-desc">
                                                {language}
                                            </div>
                                        </div>

                                        <div className="dashboard-form-item-submit-item">
                                            <Button className="feature-submit-button" onClick={() => resetALL()} aria-expanded={isLoading} aria-controls="jd-generator">เริ่มใหม่อีกครั้ง</Button>

                                        </div>
                                    </div>

                                </> :
                                <div className="dashboard-form-item-control">
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Title (ตำแหน่งงาน)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Group>
                                            {
                                                autocompleteList && autocompleteList.length > 0 &&
                                                <Typeahead
                                                    onChange={(e) => handleJobRoleChange(e)}
                                                    id="valid-styles-example"
                                                    inputProps={{
                                                        className: 'dashboard-input',
                                                    }}
                                                    options={autocompleteList}
                                                    allowNew
                                                />
                                            }

                                        </Form.Group>
                                        {
                                            (!jobTitle && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณากรอก Job title
                                            </Form.Text>
                                        }
                                    </div>
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Description (รายละเอียดงาน)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Control as="textarea" className="dashboard-input-textarea" cols={10} rows={5} onChange={(e) => onTextChange(e, setJobDescription)}></Form.Control>
                                        {
                                            (!jobDescription && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณากรอก Job Description
                                            </Form.Text>
                                        }
                                    </div>
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Experience (ประสบการณ์ทำงานในตำแหน่งนี้)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Control type="number"

                                            ref={textFieldRefEXP}

                                            min="0" max="10" className="dashboard-input" onChange={(e) => onTextChange(e, setJobExperience)}></Form.Control>
                                        {
                                            (!jobExperience && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณากรอก Job Experience
                                            </Form.Text>
                                        }
                                        {
                                            (Number(jobExperience) < 0 && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                ไม่สามารถกรอกค่าน้อยกว่า 0
                                            </Form.Text>
                                        }
                                    </div>
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Salary (เงินเดือนของตำแหน่งงานนี้)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Control
                                            ref={textFieldRef}
                                            type="number" className="dashboard-input" onChange={(e) => onTextChange(e, setJobSalary)}></Form.Control>
                                        {
                                            (!jobSalary && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณากรอก Job Salary
                                            </Form.Text>
                                        }
                                        {
                                            (Number(jobSalary) < 0 && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                ไม่สามารถกรอกค่าน้อยกว่า 0
                                            </Form.Text>
                                        }
                                    </div>
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Questions Amount (จำนวนคำถาม)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Control type="number" className="dashboard-input" onChange={(e) => onTextChange(e, setQuestionAmount)}></Form.Control>
                                        {
                                            (!questionAmount && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณากรอก จำนวนคำถาม
                                            </Form.Text>
                                        }
                                        {
                                            (Number(questionAmount) < 0 && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                ไม่สามารถกรอกค่าน้อยกว่า 0
                                            </Form.Text>
                                        }
                                    </div>
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">ภาษาที่ต้องการสร้าง ชุดคำถามสำหรับสัมภาษณ์<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Select className="dashboard-input" onChange={handleLanguageChange} >
                                            <option value="">เลือกภาษา</option>
                                            <option value="THAI" >ไทย (Beta version)</option>
                                            <option value="ENGLISH" >English</option>
                                        </Form.Select>
                                        {
                                            (!language && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณาเลือกภาษา
                                            </Form.Text>
                                        }
                                    </div>
                                    {
                                        profileMod &&
                                        <div className="d-flex">
                                            {
                                                creditModel && creditModel.credit > 0 ?
                                                    <Button className="feature-submit-button" onClick={() => submit()} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.interview_question.button}</Button>
                                                    : <Button className="feature-submit-button-gray" onClick={submitWithOutCredit} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.interview_question.button}</Button>
                                            }


                                        </div>
                                    }

                                </div>
                        }
                    </div>
                    <div className="">{
                        isLoading && !result ? <>
                            <LoadingComponent feature={TRICK_JSON[3].loading} listTrick={TRICK_JSON[3]} ></LoadingComponent> :
                        </> :
                            result && <ResultClinicComponent isExample={isExample} type={languageConfig.content.interview_question.title} resultStr={result} isClose={false} isShare={isShare} isShareImg={false} setShowShare={setIsShare} ></ResultClinicComponent>
                    }

                    </div>
                </div>
            </div>
        </>
    )
}

export default InterviewQuestionComponent;