import { Form } from "react-bootstrap";
import "./account.scss";
import { useContext, useEffect, useState } from "react";
import ProfileSCModel from "../../model/profile-sc.model";
import languageConfig from "../../language-config";
import INDUSTRY_JSON from "../../assets/json/industry.json";
import RegisterModel from "../../model/register/register.model";
import { Alert, Button, CircularProgress, Snackbar } from "@mui/material";
import tokenService from "../../services/token.service";
import { ProviderContext } from "../../context/provider.context";
import GPTService from "../../services/gpt.service";
const AccountComponent = () => {
    const { setProfileModel }: any = useContext(ProviderContext)
    const [profileMod, setProfileMod] = useState<ProfileSCModel>();
    const [isSubmit, setIsSubmit] = useState(false);
    const [registerModel, setRegisterModel] = useState<RegisterModel>();
    const [validationEmail, setValidationEmail] = useState(false);
    const [isModalSnack, setIsModalSnack] = useState(false);


    const getProfile = async () => {

        GPTService.getCompanyProfile("").then((res) => {

            if (res) {
                setProfileMod(res.data);
            }

        })

    }
    useEffect(() => {
        if (profileMod) {
            const registerMod: RegisterModel = {
                company_email: profileMod.company_email!,
                company_industry: profileMod.company_industry!,
                company_name: profileMod.company_name!,
                company_size: profileMod.company_size!,
                user_uuid: profileMod.uuid
            }
            setRegisterModel(registerMod);
        } else {
            getProfile();
        }
    }, [profileMod])
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsModalSnack(false);
    };

    const validateEmail = (email: string) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const onTextChange = (action, value) => {
        if (registerModel) {
            const registerMod = { ...registerModel }
            if (action == 'email') {
                console.log(validateEmail(value));
                if (validateEmail(value)) {
                    registerMod.company_email = value
                    setValidationEmail(false);
                } else {
                    setValidationEmail(true);
                }
            }
            if (action == 'company_name') {
                registerMod.company_name = value;
            }

            setRegisterModel(registerMod)
        }

    }
    const onUpdateProfile = () => {
        if (registerModel) {
            setIsSubmit(true);

            if (profileMod) {
                registerModel.user_uuid = profileMod.uuid;
                console.log(registerModel);

                if (registerModel.company_email 
                    && registerModel.company_industry 
                    && registerModel.company_name 
                    && registerModel.company_size 
                    && registerModel.user_uuid) {
                    GPTService.updateCompanyProfile(registerModel, profileMod.uuid).then((res) => {
                        if (res) {
                            const result = res.data as RegisterModel;
                            const profileMods: ProfileSCModel = profileMod;
                            profileMods.company_name = result.company_name;
                            profileMods.company_industry = result.company_industry;
                            profileMods.company_email = result.company_email;
                            profileMods.company_size = result.company_size;
                            tokenService.setProfile(profileMods);
                            setProfileMod(profileMods);
                            setIsModalSnack(true);

                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            }
        }
    }
    const onSelectChange = (action, value) => {
        if (registerModel) {
            const registerMod = { ...registerModel }
            if (action == 'industry') {
                registerMod.company_industry = value;
            }
            if (action == 'company_size') {
                if(value == "888"){
                    registerMod.company_size = "";
                }else{
                    registerMod.company_size = value;
                }
              
            }
            setRegisterModel(registerMod)
        }
    }

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        setIsModalSnack(true);
    }
    return (

        <>
            <div className="account-main">
                {
                    registerModel &&

                    <div className="account-main-control">
                        <div className="account-main-text-control">

                            {/* <span className="account-main-text-title">ID ของคุณ : <span className="account-main-text-desc">{registerModel.user_uuid}</span>
                                <span className="account-main-text-link" onClick={() => copyText(registerModel.user_uuid)}>คัดลอก</span></span> */}
                        </div>
                        <div className="account-main-grid-form">

                            <div className="account-main-item-control">
                                <Form.Label htmlFor="position" className="account-main-item-label">{languageConfig.register_page.company_name}</Form.Label>

                                <Form.Control type="text" value={registerModel.company_name}
                                    className="account-main-item-input-form"
                                    onChange={(e) => onTextChange('company_name', e.target.value)}></Form.Control>
                                {
                                    (isSubmit && !registerModel.company_name) &&

                                    <span className="error-text-size">
                                        {languageConfig.error.input_error}
                                    </span>
                                }

                            </div>
                            <div className="account-main-item-control">
                                <Form.Label htmlFor="position" className="account-main-item-label">{languageConfig.register_page.industry}</Form.Label>
                                <Form.Select className="account-main-item-input-form"
                                    value={registerModel.company_industry}
                                    onChange={(e) => onSelectChange('industry', e.target.value)} >
                                    {
                                        INDUSTRY_JSON.map((item: any, index: number) => {

                                            return (
                                                <option value={item.nameTh} key={index}>{item.nameTh}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                                {
                                    (isSubmit && !registerModel.company_industry) &&

                                    <span className="error-text-size">
                                        {languageConfig.error.select_error}
                                    </span>
                                }

                            </div>
                            <div className="account-main-item-control">
                                <Form.Label htmlFor="position" className="account-main-item-label">{languageConfig.register_page.company_size}</Form.Label>
                                <Form.Select className="account-main-item-input-form"
                                    value={registerModel.company_size}
                                    onChange={(e) => onSelectChange('company_size', e.target.value)} >
                                    <option value="888" selected>เลือกขนาดบริษัท</option>
                                    <option value="1-10 คน">1-10 คน</option>
                                    <option value="11-50 คน">11-50 คน</option>
                                    <option value="51-200 คน">51-200 คน</option>
                                    <option value="201-500 คน">201-500 คน</option>
                                    <option value="501-1,000 คน">501-1,000 คน</option>
                                    <option value="มากกว่า 1,000 คน">มากกว่า 1,000 คน</option>

                                </Form.Select>
                                {
                                    (isSubmit && !registerModel.company_size) &&

                                    <span className="error-text-size">
                                        {languageConfig.error.select_error}
                                    </span>
                                }

                            </div>
                            <div className="account-main-item-control">
                                <Form.Label htmlFor="position" className="account-main-item-label">{languageConfig.register_page.email_company}</Form.Label>

                                <Form.Control type="text" className="account-main-item-input-form"
                                    value={registerModel.company_email}
                                    onChange={(e) => onTextChange('email', e.target.value)}></Form.Control>
                                {
                                    (isSubmit && !registerModel.company_email && !validationEmail) ?

                                        <span className="error-text-size">
                                            {languageConfig.error.input_error}
                                        </span> : (isSubmit && validationEmail) && <span className="error-text-size">
                                            {languageConfig.error.email_validate}
                                        </span>
                                }

                            </div>


                        </div>
                        <Button className="account-main-button-submit" onClick={onUpdateProfile}>อัปเดตโปรไฟล์</Button>
                    </div>
                }
                {
                    !registerModel &&

                    <div className="account-main-loading-control">
                        <div className="account-main-loading-item">
                            <CircularProgress className="account-main-loading-item-progress"></CircularProgress>
                        </div>
                    </div>
                }

            </div>
            <Snackbar open={isModalSnack} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleClose} >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    อัปเดตโปรไฟล์ สำเร็จ
                </Alert>
            </Snackbar>
        </>
    )
}

export default AccountComponent;