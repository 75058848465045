import apiConfig from "../api-config";
import config from "../config";
import instance from "./auth-context.service";
import instanceGPT from "./auth-gpt.service";
import instanceResume from "./auth-resume.service";


const postCustom = (objectResult, apiEndpoint) => {
    const headers = {
        headers: {
            "x-api-key": config.chat_gpt.upload_resume.x_api_key
        }};
    return instanceGPT.post(apiEndpoint, objectResult,headers);
  };

const postJob = (objectResult) => {
 
    return instanceGPT.post(apiConfig.chat_gpt.path.job_post_generate, objectResult);
}

const uploadResume = (formData) => {
    return instanceResume.post(apiConfig.upload_resume, formData)
}

const postResultResumeChecker = (resume_key, jobDesc: string, jobTitle: string, uuid: string) => {
    const objectResult = new FormData();
    objectResult.append("resume_key", resume_key);
    objectResult.append("job_description", jobDesc);
    objectResult.append("job_title", jobTitle);
    objectResult.append("uuid", uuid);
  
    const headers = {
        headers: {
            'x-api-key': config.chat_gpt.resume_checker.x_api_key,
            'content-type': 'multipart/form-data'
        }};
    return instanceGPT.post("/resume/analyze", objectResult,headers)
}

const getJDSuggestion = () => {

    return instanceGPT.get(apiConfig.chat_gpt.path.jd_suggest)
}

const getCredit = () => {

    return instanceGPT.get(apiConfig.chat_gpt.path.get_credit);
}

const getLogResponse = (log_uuid) => {
    return instanceGPT.get(apiConfig.chat_gpt.path.log + log_uuid);
}

const postAnswerInterviewQuestion = (objectResult) => {
    return instanceGPT.post(apiConfig.chat_gpt.path.result,objectResult);
}

const getJobPost = (id) => {

    return instanceGPT.get("/job_post/"+id)
}

const applyJob = (objectResult, id) => {

    return instanceGPT.post("/job_post/"+id+"/apply", objectResult)
}

const getCompanyProfile = (uuid) => {

    return instanceGPT.get(apiConfig.chat_gpt.path.company_profile);
}

const updateCompanyProfile = (objectResult, uuid) => {

    return instanceGPT.put(apiConfig.chat_gpt.path.company_profile,objectResult)
}
const createCompanyProfile = (objectResult, uuid) => {

    return instanceGPT.post(apiConfig.chat_gpt.path.company_profile,objectResult)
}
  
  const GPTService = {
    postCustom,
    postJob,
    uploadResume,
    postResultResumeChecker,
    getJDSuggestion,
    getCredit,
    getLogResponse,
    postAnswerInterviewQuestion,
    getJobPost,
    applyJob,
    getCompanyProfile,
    updateCompanyProfile,
    createCompanyProfile
  };
  
  export default GPTService;