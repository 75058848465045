import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material"
import { Modal } from "react-bootstrap"
import "./audience-import.scss";
import { useEffect, useState } from "react";
import CRMServices from "../../../../../services/crm.service";
import AudienceImportSheetPostModel from "../../../../../model/audience/audience-import-sheet-post.model";
import config from "../../../../../config";

const AudienceImportComponent = ({ isOpen, handleClose }: any) => {

    const [importModel, setImportModel] = useState<any>();
    const [totalCount, setTotalCount] = useState(0);
    const [postObject, setPostObject] = useState<AudienceImportSheetPostModel>();
    const [isSubmit, setIsSubmit] = useState(false);
    const [name, setName] = useState("");
    const [audiencesKey, setAudiencesKey] = useState<any>();
    const [isSheetError, setIsSheetError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sheetID, setSheetID] = useState("");
    const [objectPostMod , setObjectPostMod] = useState<{}>();
    const urlDefault = "https://docs.google.com/spreadsheets/d/168Tzl95BtB0yU5mcjlz5xsZ1YpaOijqrMUD06EB_tew/edit#gid=0";



    useEffect(() => {
        if (!postObject) {
            const postModel: AudienceImportSheetPostModel = {
                audience_name: "",
                sheet_audiences: [],
                sheet_id: config.crm.sheet_id
            }
            setPostObject(postModel);
        }
    }, [postObject])


    const getImportFromSheet = () => {
        setIsLoading(true);
        CRMServices().getSheetsImport(sheetID).then((res) => {
            console.log(res);

            if (res) {
                setIsLoading(false);
                setImportModel(res);

            }

        })
    }

    const onInputChange = (action, value) => {
        if (action == 'name') {
            setName(value);
        } else if (action == 'sheetId') {
            if (checkIDSheets(value)) {
                setSheetID(checkIDSheets(value))
            }
        }
    }

    const onCheck = (index, key, value, count, status) => {
        console.log(value + " : " + count);

        if (!audiencesKey) {
            const objectAudienceKey: Object = {
            }
            console.log(objectAudienceKey)
        } else {
            const objectAudienceKey = { ...audiencesKey }
            objectAudienceKey[key] = [];
            console.log(objectAudienceKey)
        }

        const objectPost = {...objectPostMod};
        if(objectPost[key] && objectPost[key].length > 0){
        }else{
            objectPost[key]= new Array();
        }

        if(status){
            objectPost[key].push(value);
        }else{
            const result = objectPost[key].filter(item => item !== value);
            objectPost[key] = result;
            console.log(result);
        }
        setObjectPostMod(objectPost)
        let countOrg = totalCount;
        if (status) {
            countOrg += count;
        } else {
            countOrg -= count;
        }

        setTotalCount(countOrg);
    }

    useEffect(() => {
        if(objectPostMod){
            console.log(objectPostMod);
        }

    },[objectPostMod])
    

    const submit = () => {

        if (postObject) {
            setIsSubmit(true);
            if(name && sheetID){
                postObject.audience_name = name;
                postObject.sheet_id = sheetID;

                if(objectPostMod){
                    const filteredData = Object.fromEntries(
                        Object.entries(objectPostMod).filter(([key, value] :any) => value.length > 0)
                      );
                      console.log(filteredData)
                      postObject.sheet_audiences = filteredData;
                      setIsLoading(true);
                      CRMServices().postSheetsAudience(postObject).then((res) => {
                        setIsLoading(false);
                        
                        if(res){
                            console.log(res);
                            handleClose();
                        }
                      })
                }
             
            }
        }
    }
    const submitSheetUrl = () => {
        if (sheetID) {
            getImportFromSheet();
        }
    }

    const checkIDSheets = (url) => {

        const regex = /\/d\/([a-zA-Z0-9-_]+)\//;
        const match = url.match(regex);

        if (match && match[1]) {
            const sheetId = match[1];
            setIsSheetError(false);
            return sheetId;
        } else {
            setIsSheetError(true);
            return "";
        }
    }

    const clickSheetID = () => {
        if(sheetID){
            window.open("https://docs.google.com/spreadsheets/d/"+sheetID, "_blank");
        }
    }

    return (

        <div className="audience-import-modal">
            <Modal show={isOpen} onHide={handleClose} className="audience-import-modal" backdrop={'static'} >
                <Modal.Header closeButton>
                    <Modal.Title>Audience ที่นำเข้า</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="audience-import-main">

                        {
                            postObject ?


                                <div className="audience-import-main-control">
                                    {
                                        !importModel && <div className="audience-import-name-control">
                                            <span className="audience-import-title">ใส่ URL Google sheets:</span>
                                            <FormControl className="create-audience-form-margin">

                                                <TextField id="standard-basic" onChange={(e) => onInputChange('sheetId', e.target.value)}
                                                    variant="outlined" />
                                                {
                                                    isSubmit && !sheetID &&
                                                    <span className="audience-import-required-error">กรุณาใส่ URL</span>
                                                }
                                                {
                                                    isSheetError &&
                                                    <span className="audience-import-required-error">Url ไม่ถูกต้อง</span>
                                                }
                                            </FormControl>
                                            <Button className="audience-main-button-submit" onClick={submitSheetUrl}>Submit</Button>
                                        </div>
                                    }
                                    {
                                        isLoading &&
                                        <div className="audience-import-loading-control">
                                            <div className="audience-import-loading-progress-control">
                                                <CircularProgress className="audience-import-loading-progress"></CircularProgress>
                                                <span className="audience-import-loading-text">กำลังโหลด</span>
                                            </div>
                                        </div>
                                    }

                                    {
                                        importModel && !isLoading &&

                                        <>
                                            <span className="audience-import-title">Total Audiences:
                                                <span className="audience-import-total-count">{totalCount.toLocaleString()}</span></span>
                                            <div className="audience-import-divider"></div>
                                            <div className="audience-import-sheet-control">
                                                <span className="audience-import-title">ID Sheets: 
                                                <span className="audience-import-sheet-title" onClick={clickSheetID}>{sheetID}</span></span>
                                            </div>
                                            <div className="audience-import-name-control">
                                                <span className="audience-import-title">ชื่อ Audience</span>
                                                <FormControl className="create-audience-form-margin">

                                                    <TextField id="standard-basic" value={name} onChange={(e) => onInputChange('name', e.target.value)}
                                                        variant="outlined" />
                                                    {
                                                        isSubmit && !name &&
                                                        <span className="audience-import-required-error">กรุณากรอกชื่อ</span>
                                                    }
                                                </FormControl>
                                            </div>
                                            <div className="audience-import-main-grid">

                                                {
                                                    Object.keys(importModel).map((key, i) => {
                                                        return (
                                                            key != "total_count" &&
                                                            <div className="audience-import-main-item-control" key={i}>

                                                                <span className="audience-import-main-item-title">{key.toString().toLocaleUpperCase()}</span>
                                                                <FormGroup className="audience-import-main-item-grid">
                                                                    {
                                                                        importModel[key].map((item: any, index: number) => {
                                                                            return (
                                                                                <FormControlLabel className="audience-import-main-item-grid-title" key={index}
                                                                                    control={<Checkbox
                                                                                        onChange={(e) => onCheck(index, key, item[key], item.count, e.target.checked)} />}
                                                                                    label={item[key] + " (" + item.count + ")"} />
                                                                            )
                                                                        })
                                                                    }
                                                                </FormGroup>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <Button className="audience-main-button-submit" onClick={submit}>Submit</Button>
                                        </>
                                    }



                                </div> : <div className="audience-import-loading-control">
                                    <div className="audience-import-loading-progress-control">
                                        <CircularProgress className="audience-import-loading-progress"></CircularProgress>
                                        <span className="audience-import-loading-text">กำลังโหลด</span>
                                    </div>
                                </div>
                        }


                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AudienceImportComponent;