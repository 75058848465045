import { Modal } from "react-bootstrap";
import "./result.modal.scss";
import { useContext, useEffect, useState } from "react";
import { LogResultModel } from "../model/log-result.model";
import apiConfig from "../api-config";
import ConvertResultServices from "../services/convert-result.service";
import InputJobPostModel from "../model/job-post/input-job-post.model";
import config from "../config";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import ResultClinicComponent from "../components/features/result-clinic/result-clinic";
import { Button } from "@mui/material";
import UtilityService from "../utils/utilities";


const ResultModal = ({ open, setOpen, result, setShowShare }: any) => {

    const [resultStr, setResultStr] = useState("");
    const [resultModel, setResultModel] = useState<LogResultModel>()
    const [isShare, setIsShare] = useState(false);
    const [isShareImg, setIsShareImg] = useState(false);
    const [openShareImg, setOpenShareImg] = useState(false);
    const [inputJobPost, setInputJobPost] = useState<InputJobPostModel>();
    const navigate = useNavigate();
    const handleClose = () => {
        setOpen(false);
    };
    const setShowShareInt = () => {
        if (inputJobPost) {
            console.log(inputJobPost);
            setShowShare(inputJobPost);
        }
    }
    useEffect(() => {

        if (result) {
            checkResutlStr(result);


        }
    }, [result])

    const checkResutlStr = async (result) => {
        const resultMod = result as LogResultModel;
        console.log(resultMod);
        UtilityService().clickSendEvent("check_"+resultMod.type, "click");
        if (resultMod.type == "JD Generator") {
            const results = await ConvertResultServices().JDGenerator(resultMod.log_response, resultMod.log_request);
            setResultStr(results)
            setIsShare(true);
            setIsShareImg(false);
        } else if (resultMod.type == "Job Posting Generator") {
            const results = await ConvertResultServices().JobPostSocial(resultMod.log_response, true);
            console.log(results);
            setIsShare(true);
            setIsShareImg(true);
         
            console.log(results);
            const input = resultMod.log_request as InputJobPostModel;
     
            input.url_job = config.app.home + "/job/" + resultMod.log_response.job_post_uuid;
            let strMsg = results;
            strMsg += "<br><br>";
            strMsg += "Apply URL : " + "<a href='"+config.app.home + "job/" + resultMod.log_response.job_post_uuid+"' target='_blank'> คลิก </a>";
            setResultStr(strMsg);
            setInputJobPost(input);

        } else if (resultMod.type == "Salary Offering") {
            const results = await ConvertResultServices().SalaryOffering(resultMod.log_response, resultMod.log_request);
            setIsShare(false);
            setIsShareImg(false);
            setResultStr(results);
            console.log(results);
        } else if (resultMod.type == "Resume Pre-Screening") {
            const results = await ConvertResultServices().ResumePreScreen(resultMod.log_response, resultMod.log_request.job_title);
            console.log(results);
            setIsShare(false);
            setIsShareImg(false);
            setResultStr(results);
            console.log(results);

        } else if (resultMod.type == "JD Checker") {
            const results = await ConvertResultServices().JDChecker(resultMod.log_response, resultMod.log_request.job_title);
            setIsShare(false);
            setIsShareImg(false);
            setResultStr(results);
            console.log(results);
        } else {
            const results = await ConvertResultServices().InterviewGenerator(resultMod.log_response, resultMod.log_request.job_title);
            setIsShare(false);
            setIsShareImg(false);
            setResultStr(results);
            console.log(results);
        }
    }



    return (

        <>
            <Modal show={open} onHide={handleClose} dialogClassName="result-modal">
              
                <Modal.Body  >
                <div className="result-modal-close-control">
                        <Button onClick={handleClose} className="result-modal-close-text">ปิด</Button>
                    </div>
                    {
                        result && 
                    <ResultClinicComponent type={result?.type} resultStr={resultStr} isShare={isShare} isShareImg={isShareImg} setShowShare={setShowShareInt} handleClose={handleClose} ></ResultClinicComponent>

                    }
                   
                </Modal.Body>
               


            </Modal>

           
        </>
    )
}

export default ResultModal;