
import { Container } from "react-bootstrap";
import "./footer-landing.scss";
import LOGO_CLINIC_WHITE from "../../../assets/images/logo-clinic-main-white.png";
import templateConfig from "../../../template-config";

const FooterLandingComponent = () => {


return (
    <>
        <div className="footer-landing">
            <Container>
                <div className="footer-landing-control">
                    <div className="footer-landing-logo-control">
                        <img src={templateConfig.assets_env.main_logo} className="footer-landing-logo-img"></img>
                    </div>
                </div>
            </Container>
        </div>
    </>
)
}

export default FooterLandingComponent;