import config from "../config"
export default function ConvertResultServices() {

    return {

        async SalaryOffering(answerModel, objectResult) {
            if (answerModel) {
                let strMessage = "<br><div style='display: flex; flex-direction: column;'>";
                strMessage += "<span style='text-align: center; font-size: 13px;'>ทางเราแนะนำเงินเดือนสำหรับตำแหน่ง <b>" + objectResult["job_title"] + "</b> ด้วยประสบการณ์ประมาณ " + objectResult["years"] + " ปี ได้ดังนี้. </span> <br> <br>";
                if (answerModel.salary_min) {
                    strMessage += "<b style='text-align: center; font-size: 15px;'>เงินเดือน เริ่มต้น</b> <br> " +

                        "<span style='text-align: center; font-size: 30px; font-weight: bold;'>" + answerModel.salary_min.toLocaleString() + " บาท </span> <br><br>";
                }

                if (answerModel.salary_max) {
                    strMessage += "<b style='text-align: center; font-size: 15px;'>ถึง</b> <br> <br> " +

                        "<span style='text-align: center; font-size: 30px; font-weight: bold;'>" + answerModel.salary_max.toLocaleString() + " บาท </span> <br><br>";
                }

                strMessage += "<br> <br> </div>";
                console.log(strMessage);

                return strMessage;
            } else {
                return "500";
            }
        },
        async ResumePreScreen(result, jobTitle) {
            if (result.score) {

                let strMessage = "<div style='text-align: center; font-size: 16px;'>Resume applicable for <br><b>" + jobTitle + "</b> <br>" +
                    "<br> <b><span style='font-size: 40px;'>" + result.score + " % </span></b>  <br><br> </div>";

                strMessage += "<div style='text-align: center;'>Conclusion: <br>" +
                    "<span style='font-size: 12px; font-weight: bold; '> " + result.matching_detail + "</span> <br><br>" +
                    "<span>Skills:</span> <br> <span style='font-size: 12px; font-weight: bold; '>" + result.skills.key_skills + "</span> <br>" +
                    "<b>" + result.skills.opportunity_skills + "</b></div><br><br>";

                if (result.salary_suggestion && result.salary_suggestion.min && result.salary_suggestion.max) {
                    strMessage += "<div style='text-align: center;'>Salary suggestion: <br>" +
                        "<span style='font-size: 20px; font-weight: bold; '> " + result.salary_suggestion.min.toLocaleString() + " - " + result.salary_suggestion.max.toLocaleString() + " บาท</span> </div>";
                }

                return strMessage;
            } else {
                return "500";
            }
        },
        async JobPostSocial(answerModel, isHistory?) {
            if (isHistory) {
                return answerModel.job_post_generated;
            } else {
                if (answerModel.job_post_generated) {
                    return answerModel.job_post_generated;

                } else {
                    return "500"
                }
            }


        },
        async InterviewGenerator(answerModel, jobTitle) {
            if (answerModel.questions) {
                let questionsHtml = " <span style='text-align: center; font-size: 15px;'> Interview questions guideline for <b>" + jobTitle + "</b> </span><br><br>";
                answerModel.questions?.map((item: string, index: number) => {
                    questionsHtml += "<div style='border: 1px solid #888; border-radius: 4px; padding: 6px;'>" +
                        "<span style='font-size: 14px;'>" + (index + 1) + ". " + item + "</span> </div><br>";
                })

                return questionsHtml;
            } else {
                return "500";
            }
        },
        async JDChecker(answerModel, objectResult) {
            if (answerModel) {


                let strMessage = "";
                strMessage += "This is results for <b>" + objectResult["job_title"] + "</b> position. <br>";

                if (answerModel.score) {
                    strMessage += "<br>Your JD score is <b style='font-size: 30px;'>" + answerModel.score + "</b>";
                }
                if (answerModel.bad && answerModel.bad.length > 0) {
                    strMessage += "<br><br><b>Improvement:</b> <br> ";
                    answerModel.bad.map((item: string, index: number) => {
                        if (index == answerModel.bad!.length - 1) {
                            strMessage += (index + 1) + ". " + item + ".";
                        } else {
                            strMessage += (index + 1) + ". " + item + ". <br>";
                        }
                    })

                }

                if (answerModel.good && answerModel.good.length > 0) {
                    strMessage += "<br><br><b>Need to include in JD:</b> <br> ";
                    answerModel.good.map((item: string, index: number) => {
                        if (index == answerModel.good!.length - 1) {
                            strMessage += (index + 1) + ". " + item + ".";
                        } else {
                            strMessage += (index + 1) + ". " + item + ". <br>";
                        }
                    })

                }


                strMessage += "<br> <br>";

                return strMessage;

            } else {
                return "500";
            }
        },
        async JDGenerator(answerModel, objectResult, isHistory?) {



            if (answerModel.job_description) {
                let strMessage = ""
                if (objectResult["language"] == "ENGLISH") {
                    strMessage = "JD for <b>" + objectResult["job_title"] + "</b> :<br> <br>";
                } else {
                    strMessage = "JD ที่ถูกสร้างในตำแหน่ง <b>" + objectResult["job_title"] + "</b> :<br>";
                }

                strMessage += answerModel.job_description + "<br> <br>";

                if (answerModel.qualifications) {
                    strMessage += "<b>Qualification</b> : <br>";

                    answerModel.qualifications.map((item: String, index: number) => {
                        strMessage += (index + 1) + ". " + item + " <br>";
                    })
                    strMessage += "<br>"
                }
                if (answerModel.skills) {
                    if (objectResult["language"] == "ENGLISH") {
                        strMessage += "<b>Need skills</b> : <br>";
                    } else {
                        strMessage += "<b>ทักษะที่ควรมีในตำแหน่งงานนี้</b> : <br>";
                    }


                    answerModel.skills.map((item: String, index: number) => {
                        if (index == (answerModel.skills!.length - 1)) {
                            strMessage += item;
                        } else {
                            strMessage += item + ", ";
                        }

                    })

                    strMessage += "<br><br>"
                }
                if (answerModel.salary_min && answerModel.salary_max) {
                    if (objectResult["language"] == "ENGLISH") {
                        strMessage += "<b>Starting salary at</b> : " + (answerModel.salary_min.toLocaleString()) + " - " + (answerModel.salary_max.toLocaleString()) + " THB<br><br>";
                    } else {
                        strMessage += "<b>เงินเดือนที่สามารถจ้างพนักงานได้ เริ่มต้น</b> : " + (answerModel.salary_min.toLocaleString()) + " - " + (answerModel.salary_max.toLocaleString()) + " บาท<br><br>";
                    }

                }

                return strMessage;

            } else {
                return "500"
            }
        }
    }
}