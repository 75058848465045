import templateConfig from "../../../template-config";
import FooterLandingComponent from "../footer-landing/footer-landing";
import TalentSauceFooterComponent from "../talent-sauce/footer/talent-sauce-footer";
import "./privacy.scss";


const PrivacyComponent = () => {




    return (
        <>
            <div className="privacy-page-main">
                <img src={templateConfig.assets_env.main_logo_active} className="privacy-page-icon"></img>
                <div className="privacy-content-control">
                    <span className="privacy-content-title">นโยบายความเป็นส่วนตัว </span>
                    <span className="privacy-content-desc">เวอร์ชั่น 1.7 </span>
                    <span className="privacy-content-desc">23 พฤศจิกายน 2566 </span>
                    <br></br>
                    <span className="privacy-content-desc">
                        ScoutOut เคารพในความเป็นส่วนตัวและข้อมูลส่วนบุคคลของผู้ใช้บริการ ในการนี้ ScoutOut จึงได้มีการกำหนดนโยบายความเป็นส่วนตัวเพื่ออธิบาย การจัดเก็บข้อมูล การใช้ข้อมูล และการเปิดเผยข้อมูลของผู้ใช้บริการในกรณีต่างๆ ตามที่ผู้ใช้บริการได้แสดงความยินยอมไว้กับ ScoutOut
                        <br></br> <br></br>
                        ScoutOut ขอแนะนำให้ผู้ใช้บริการอ่านนโยบายความเป็นส่วนตัวโดยละเอียดเพื่อเข้าใจถึงแนวปฏิบัติของ ScoutOut ในการดูแลข้อมูลส่วนบุคคลของผู้ใช้บริการ และนโยบายความเป็นส่วนตัวนี้ อาจมีการปรับปรุง แก้ไข เปลี่ยนแปลง เป็นครั้งคราว ตามที่ ScoutOut พิจารณาว่าเหมาะสม
                        <br></br> <br></br>
                        การลงทะเบียนในเว็บไซต์ของเรา หรือบริการอื่นๆ ของ ScoutOut ที่มีการเปิดเผยข้อมูลส่วนบุคคลให้กับ ScoutOut แสดงว่าผู้ใช้บริการ
                        <br></br> <br></br>
                        ยินยอมให้ ScoutOut และ/หรือ กลุ่มบริษัทของ ScoutOut เก็บ ใช้ และ/หรือ ประมวลผล ข้อมูลส่วนบุคคลของผู้ใช้บริการ เพื่อวัตถุประสงค์ตามที่ระบุไว้เบื้องล่าง
                        ยินยอมให้ ScoutOut และ/หรือ กลุ่มบริษัทของ ScoutOut เปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการ ให้กับผู้ให้บริการ ตัวแทน ที่เป็นบุคคลที่สามของ ScoutOut และ/หรือกลุ่มบริษัทของ ScoutOut (ไม่ว่าในหรือนอกประเทศไทย) เพื่อวัตถุประสงค์ตามที่ระบุไว้เบื้องล่าง
                        หากผู้ใช้บริการมีคำถาม หรือข้อกังวลเกี่ยวกับนโยบายความเป็นส่วนตัวนี้ กรุณาดูข้อมูลที่ท้ายหนังสือฉบับนี้เพื่อติดต่อเรา
                        <br></br> <br></br>
                        ภายใต้นโยบายความเป็นส่วนตัวนี้ คำต่อไปนี้จะมีความหมายตามที่ระบุไว้เบื้องล่าง นอกจากจะได้กำหนดไว้เป็นอย่างอื่น
                        <br></br> <br></br>
                        “ScoutOut” หมายถึง บริษัท จัดหางาน สเกาท์ เอาท์ จำกัด
                        <br></br> <br></br>
                        “กลุ่มบริษัทของ ScoutOut” หมายถึง ScoutOut และ บริษัทในเครือ
                        <br></br> <br></br>
                        “บริการ” หมายถึง บริการที่นำเสนอบนเว็บไซต์ บนแอพพลิเคชั่นมือถือ หรือบริการอื่นๆ ของ ScoutOut หรือให้บริการโดย ScoutOut (“Powered by ScoutOut”) เช่น บริการ JobNOW (jobnow.work) บริการ HR9 & HR9 Clinic (hr9.scoutout.co & hr9-clinic.scoutout.co) บริการ jobHACK (jobhack.co) บริการ CareerCraft (careercraft.co) บริการ OneProfile (oneprofile.me) สินค้าซอฟแวร์ ข้อมูล เนื้อหา และสื่ออื่นๆ ที่มาจาก ScoutOut เพื่อเป็นประโยชน์ต่อผู้ใช้บริการ สมาชิก ผู้ว่าจ้าง ผู้หางาน ผู้ฝึกสอนและผู้ใช้บริการทั่วไป โดยมีทั้งบริการแบบไม่มีค่าบริการและมีค่าบริการ
                        <br></br> <br></br>
                        “เนื้อหา” หมายถึง ข้อมูลที่ถูกแสดง ใส่ เขียน หรือสร้างในระบบของ ScoutOut ซึ่งรวมถึงแต่ไม่จำกัดเพียง ข้อมูลเงินเดือน บทวิเคราะห์ผู้หางาน บริษัท ผู้ฝึกสอนหรือผู้ว่าจ้าง บทวิเคราะห์การสัมภาษณ์ โปรไฟล์ผู้หางาน บริษัท ผู้ฝึกสอนหรือผู้ว่าจ้าง โฆษณาเกี่ยวกับตำแหน่งงาน โฆษณาอื่นๆ เนื้อหาและคอร์สพัฒนาทักษะทางอาชีพ ใบสมัครงาน เรซูเม่ การตอบรับของบริษัทหรือผู้ว่าจ้าง โพสต์ ประกาศ ความคิดเห็น ข้อสังเกตุ ไฟล์ รูปภาพ ภาพถ่าย อีเมล โดย ScoutOut สมาชิก ผู้ใช้บริการ ผู้ลงโฆษณา หรือ บุคคลที่สาม ผ่านบริการของ ScoutOut
                        <br></br> <br></br>
                        “ผู้หางาน” หมายถึง ผู้ใช้งานที่ใช้บริการใน การดูหรือหางาน การทำแบบทดสอบข้อมูลเงินเดือน ข้อมูลทางบุคคลิก หรือ บริการเนื้อหาอื่นใดเกี่ยวกับการหางาน ทำงาน หรือ พัฒนาความสามารถในการทำงาน
                        <br></br> <br></br>
                        “ผู้ว่าจ้าง” หมายถึง ผู้ใช้งานที่เป็นองค์กร บริษัท นิติบุคคล หรือผู้สรรหาบุคลากรให้กับบริษัท ที่อาจมีการลงโฆษณาเกี่ยวกับตำแหน่งงาน หรือโฆษณาส่งเสริมบริษัทหรืองานของบริษัท ผ่านเว็บไซต์ หรือบริการอื่นๆ ของเรา
                        <br></br> <br></br>
                        “ผู้ฝึกสอน” หมายถึง ผู้ใช้บริการ ผู้ใช้งานที่เป็นองค์กร บริษัท นิติบุคคล ที่สรรหา เขียน คิดค้น สอน เนื้อหา หรือ คอร์สพัฒนาทักษะทางอาชีพ เพื่อให้ผู้ใช้บริการอื่น หรือ สมาชิกเข้าเรียน ไม่ว่าเป็นคอร์สที่เรียนได้ฟรี หรือที่ต้องเสียค่าบริการ
                        <br></br> <br></br>
                        “ผู้ใช้บริการ”, “ท่าน” หรือ “คุณ” หมายถึง ผู้ใช้งาน ไม่ว่าจะเป็นบุคคลธรรมดา องค์กร บริษัท หรือ นิติบุคคล ผู้หางาน  ผู้ว่าจ้าง ผู้ฝึกสอน หรือผู้ให้บริการบุคคลที่สาม ที่เข้ามาใช้ระบบ เว็บไซต์ หรือบริการอื่นๆ ของ ScoutOut รวมทั้งผู้ที่เป็นและไม่ได้เป็นสมาชิก ไม่ว่าด้วยเหตุใดก็ตาม
                        <br></br> <br></br>
                        “ลงทะเบียน” หมายถึง ขั้นตอนที่ผู้หางาน ผู้ว่าจ้าง หรือ ผู้ฝึกสอนกระทำเพื่อเป็นสมาชิก โดยเปิดเผยข้อมูลให้ ScoutOut และให้ ScoutOut ได้เก็บรักษา และ เปิดเผยรายละเอียดต่างๆ ตามที่ผู้ใช้บริการต้องการเพื่อประโยชน์ในการพัฒนาทักษะทางอาชีพ หางาน จัดหา และการจ้างงาน
                        <br></br> <br></br>
                        ข้อมูลผู้หางาน  รวมถึงแต่ไม่จำกัดเพียง ข้อมูลส่วนตัว เรซูเม่ของผู้หางาน (RESUME) ข้อมูลโปรไฟล์ ใบสมัครงาน เป้าหมายอาชีพ คอร์สพัฒนาทักษะทางอาชีพที่ลงทะเบียน และประวัติการซื้อบริการ
                        ข้อมูลผู้ว่าจ้าง  รวมถึงแต่ไม่จำกัดเพียง โพสต์งาน โฆษณาของผู้ว่าจ้าง และ ข้อมูลโปรไฟล์ของผู้ว่าจ้าง
                        ข้อมูลผู้ฝึกสอน รวมถึงแต่ไม่จำกัดเพียง ข้อมูลโปรไฟล์ เนื้อหา วิดีโอ และข้อสอบของคอร์สพัฒนาทักษะทางอาชีพ และข้อมูลการลงทะเบียนของคอร์สต่างๆ
                        “สมาชิก” หมายถึง ผู้ใช้บริการ ผู้หางาน ผู้ว่าจ้าง หรือ ผู้ฝึกสอน ที่ได้มาลงทะเบียนสมาชิกกับ ScoutOut เพื่อเปิดบัญชีสมาชิก ผ่านการใส่ชื่อผู้ใช้งาน (login) และรหัสผ่าน (password) ของ ScoutOut ผ่านเบอร์โทรศัพท์ของผู้ใช้งาน หรือ ผ่านระบบยืนยันตัวตนของบุคคลที่สาม เช่น LINE Google LinkedIn และ/หรือ Facebook
                        <br></br> <br></br>
                        <span className="privacy-content-bold">การใช้ข้อมูลส่วนบุคคล</span>
                        <br></br> <br></br>
                        วัตถุประสงค์ในการเก็บข้อมูลส่วนบุคคลของผู้ใช้บริการของ ScoutOut นั้น รวมถึงแต่ไม่จำกัดเพียง
                        <br></br> <br></br>
                        เพื่อให้บริการ พัฒนาผลิตภัณฑ์ และพัฒนาการให้บริการของ ScoutOut
                        เพื่อการทำธุรกิจของ ScoutOut
                        เพื่อให้ผู้ใช้บริการมี และสามารถบริหารจัดการบัญชีสมาชิกเองได้
                        เพื่อให้ง่ายแก่การแก้ไขปัญหาของผู้ใช้บริการ (customer support) โดยพนักงานของ ScoutOut หรือ ผู้ให้บริการบุคคลที่สาม
                        เพื่อประมวลผลการใช้บริการ ความสนใจของผู้ใช้บริการในผลิตภัณฑ์ บริการ และ/หรือ ข้อมูลต่างๆ ของ ScoutOut และ เนื้อหาที่เหมาะสมกับผู้ใช้บริการ
                        เพื่อเพิ่มความสะดวกในการค้นหา การสรรหาบุคลากร ของผู้ว่าจ้าง การเปิดเผยข้อมูลที่ละเอียด และครบถ้วน เช่นข้อมูลที่ใช้ในการติดต่อ ประวัติการทำงาน และประวัติการศึกษาของผู้หางานนั้น จะทำให้โอกาสของผู้หางานนั้นมากขึ้น และการค้นหาของผู้ว่าจ้างสะดวกมากขึ้น
                        เพื่อการสื่อสารกับผู้ใช้บริการผ่านอีเมล จดหมาย โทรศัพท์ LINE chat, Facebook Messenger และ/หรือ วิธีอื่นๆ ผ่านอุปกรณ์อินเทอร์เน็ต เกี่ยวกับผลิตภัณฑ์ บริการที่ผู้ใช้บริการน่าจะสนใจ และ/หรือ กิจกรรมการตลาด ไม่ว่าจะเป็นของ ScoutOut หรือผู้ให้บริการที่เป็นบุคคลที่สามก็ตาม
                        เพื่อแสดงโฆษณา และติดตามสถิติการแสดงโฆษณาให้กับผู้ใช้บริการ ไม่ว่าในหรือนอกบริการของ ScoutOut เอง
                        เพื่อประมวลสถิติการใช้งานเว็บไซต์ แอพพลิเคชั่น และ/หรือ บริการของ ScoutOut
                        เพื่อตรวจสอบข้อมูลการให้รางวัล ไม่ว่าในการแข่งขันหรือการชิงโชคต่างๆ รวมไปถึงการเก็บข้อมูล ตามกฎหมาย และ/หรือ เพื่อแสดงหลักฐานในการออกรางวัลให้กับหน่วยงานของรัฐที่เกี่ยวข้อง
                        เพื่อใช้ในการบังคับใช้เงื่อนไขและข้อตกลงการใช้บริการของ ScoutOut กับผู้ใช้บริการ และ
                        เพื่อใช้ในการอื่น ตามที่ได้ระบุไว้ในการเก็บข้อมูลในเวลานั้นๆ
                        นโยบายความเป็นส่วนตัวนี้จะมีการใช้ควบคู่ไปกับเงื่อนไขและข้อตกลงการใช้บริการ ScoutOut ขอแนะนำให้ท่านอ่านนโยบายความเป็นส่วนตัวนี้ และเงื่อนไขและข้อตกลงการใช้บริการ อย่างละเอียดก่อนที่จะใช้บริการ และการที่ท่านใช้บริการนั้นจะหมายถึง ท่านได้ตกลงและยอมรับเงื่อนไขและข้อตกลงการใช้บริการนี้ นโยบายความเป็นส่วนตัว และกฎและข้อบังคับอื่นๆ ของ ScoutOut
                        <br></br> <br></br>
                        <span className="privacy-content-bold">ข้อมูลส่วนบุคคลของผู้ใช้บริการ</span>
                        <br></br> <br></br>
                        ข้อมูลใน ScoutOut โปรไฟล์
                        <br></br> <br></br>
                        ScoutOut อาจมีการเข้าถึง เก็บ ใช้ และประมวลข้อมูลส่วนบุคคลของผู้ใช้บริการไว้เป็นโปรไฟล์ส่วนบุคคล (“โปรไฟล์”) ซึ่งรวมถึงแต่ไม่จำกัดเพียง ชื่อ นามสกุล อีเมล์ เบอร์โทรศัพท์ วันเดือนปีเกิด ประวัติการทำงาน ประวัติการศึกษา รูปภาพของผู้ใช้บริการ เรซูเม่ (resume) ข้อมูลประกอบใบสมัคร ข้อมูลการว่าจ้าง ข้อมูลโพสต์งาน ข้อมูลโฆษณา ข้อมูลคอร์สพัฒนาทักษะทางอาชีพ ข้อมูลการลงทะเบียนคอร์สเรียน ประวัติการซื้อบริการ  ข้อมูลทางการเงินจากการซื้อขายบริการ หรือจากการได้รางวัล ที่ผู้ใช้บริการเปิดเผยให้กับ ScoutOut และ อาจมีการ เข้าถึง เก็บ ใช้ และประมวลข้อมูลอื่นๆ ในการใช้บริการของ ScoutOut ที่ไม่ได้ใช้ในการระบุตัวตนของผู้ใช้บริการ ซึ่งเราอาจเก็บข้อมูลนี้ผ่านเว็บไซต์ หรือบริการอื่นๆ ของ ScoutOut
                        <br></br> <br></br>
                        ข้อมูลโปรไฟล์ในบางส่วนอาจเป็นข้อมูลสาธารณะโดยปกติ หรือ อาจสามารถถูกเปลี่ยนเป็นข้อมูลสาธารณะได้ ตามค่าความเป็นส่วนตัวที่ท่านตั้ง (privacy settings) ScoutOut ขอแนะนำให้ท่านตรวจสอบข้อมูลที่ท่านกรอก หรือได้กรอกไว้นั้น ว่าข้อมูลเหล่านั้นอยู่ในสถานะความเป็นส่วนตัวที่ท่านต้องการเสมอๆ
                        <br></br> <br></br>
                        การลงทะเบียนผ่านระบบยืนยันตัวตนของ ScoutOut หรือของบุคคลที่สาม
                        <br></br> <br></br>
                        การใช้บริการหรือลงทะเบียน ในเว็บไซต์หรือบริการอื่นๆ ของ ScoutOut ไม่ว่าจะผ่านระบบยืนยันตัวตนของ ScoutOut หรือบุคคลที่สาม เช่น LINE Google LinkedIn และ/หรือ Facebook ก็ตามของผู้ใช้บริการ ถือว่าเป็นการยินยอมให้ ScoutOut ทำการเข้าถึง เก็บ ใช้ และประมวลข้อมูลของการใช้บริการนั้นๆ ซึ่งข้อมูลส่วนบุคคล และข้อมูลอื่นๆ นั้น อาจถูกเก็บไว้บนระบบอิเล็กทรอนิกส์ หรือฐานข้อมูล ไม่ว่าในประเทศไทย หรือนอกประเทศไทย
                        <br></br> <br></br>
                        ซึ่งหากเป็นการใช้ระบบยืนยันตัวตนของบุคคลที่สาม ScoutOut อาจมีการเข้าถึง เก็บ ใช้ และประมวลข้อมูลโปรไฟล์ของผู้ใช้บริการ ซึ่งรวมถึงแต่ไม่จำกัดเพียง ชื่อ นามสกุล อีเมล เบอร์โทรศัพท์ เพศ วันเดือนปีเกิด รูปภาพโปรไฟล์ เพจที่สนใจ (interests) รายชื่อของเพื่อน (friend list) ที่ผู้ใช้บริการยินยอมเปิดเผยให้กับ ScoutOut จากระบบนั้นๆ
                        <br></br> <br></br>
                        ทางเลือกในการเปิดเผยข้อมูลส่วนบุคคล
                        <br></br> <br></br>
                        ทั้งนี้ผู้ใช้บริการไม่มีข้อผูกพันในการเปิดเผยข้อมูลส่วนบุคคลหรือข้อมูลอื่นๆ ตามที่ ScoutOut ได้กำหนด แต่ถ้าผู้ใช้บริการไม่ส่งข้อมูลตามเงื่อนไขที่กำหนดไว้ ScoutOut อาจไม่สามารถให้บริการที่แสดงไว้ในเว็บไซต์หรือบริการอื่นๆ กับผู้ใช้บริการได้ ซึ่งรวมถึงแต่ไม่จำกัดเพียง การลงทะเบียนเพื่อเปิดบัญชีสมาชิก การสมัครงาน การโพสต์งานหรือโฆษณา การบริหารข้อมูลผู้สมัคร การซื้อหรือชำระค่าบริการ การเข้าร่วมแข่งขัน การเข้าร่วมโปรโมชั่น การตอบแบบสำรวจ การเข้าร่วมชิงโชค หรือการทำธุรกรรมอื่นๆ
                        <br></br> <br></br>
                        ทางเลือกในการรับข้อมูล ข่าวสาร บริการ และการตลาด
                        <br></br> <br></br>
                        ScoutOut และกลุ่มบริษัทของ ScoutOut อาจมีการส่งข้อมูล ข่าวสาร บริการ และการตลาดเป็นครั้งคราว เช่น งาน บริการทั้งของ ScoutOut หรือบุคคลที่สาม เนื้อหา โฆษณา รายงาน และ/หรือ เหตุการณ์อื่นๆ ที่น่าสนใจ  ให้แก่ผู้ใช้บริการทั่วไป สมาชิก ผู้หางาน ผู้ว่าจ้าง ผู้ฝึกสอน ที่ได้ยินยอมลงทะเบียนหรือตั้งสถานะ เพื่อรับข้อมูล ข่าวสาร บริการ และการตลาดเหล่านี้ ไว้ในบริการ ไม่ว่าจะผ่าน อีเมล จดหมาย โทรศัพท์ LINE chat, Facebook Messenger และ/หรือ วิธีอื่นๆ ผ่านอุปกรณ์อินเทอร์เน็ต ซึ่งผู้ใช้บริการตกลงที่จะให้ ScoutOut และกลุ่มบริษัทของ ScoutOut ใช้ข้อมูลส่วนบุคคล และ/หรือ
                        ข้อมูลของผู้ว่าจ้างของท่าน ในการส่งข้อมูล ข่าวสาร บริการ และการตลาด โดยไม่จำกัดวิธีในการสื่อสาร และไม่ว่า ScoutOut จะได้รับค่าตอบแทนในการส่งข้อมูล ข่าวสาร บริการ และการตลาด หรือไม่ก็ตาม
                        <br></br> <br></br>
                        ทั้งนี้ผู้ใช้บริการมีสิทธิ์เปลี่ยนสถานะ หรือยกเลิกการลงทะเบียนการรับข้อมูลข่าวสารบริการและการตลาด ได้ผ่านสถานะที่ตั้งไว้ในบัญชีสมาชิก ScoutOut กดลิงค์ยกเลิกการติดตาม (“Unsubscribe”) ที่อยู่ในเนื้อหานั้นๆ กดปุ่มบล็อค (“Block”) ใน LINE chat เลือกปิดรับข้อความ (“Messages”) ใน Facebook Messenger หรือ โดยการติดต่อมาที่แผนกบริการลูกค้าของ ScoutOut ตามข้อมูลด้านล่างในส่วน “การสอบถาม”
                        <br></br> <br></br>
                        <span className="privacy-content-bold">เทคโนโลยี และ การเก็บข้อมูล</span>
                        <br></br> <br></br>
                        ข้อมูลที่ถูกจัดเก็บโดยอัตโนมัติ
                        <br></br> <br></br>
                        เรามีการเก็บ ใช้ และประมวลข้อมูลที่ส่งจากบราวเซอร์หรืออุปกรณ์อินเตอร์เน็ตต่างๆ ที่ใช้ติดต่อและขอรับบริการ ซึ่งรวมถึงแต่ไม่จำกัดเพียง เลขที่ไอพี (IP Address) เลขที่ของอุปกรณ์ ชนิดของอุปกรณ์ ชนิดของบราวเซอร์ ภาษา ระบบปฏิบัติการของอุปกรณ์ เวลาในการใช้บริการ สถานที่ตั้งของอุปกรณ์ตอนใช้บริการ ข้อมูลเส้นทางการใช้งาน และเว็บไซต์ที่ทำให้ผู้ใช้บริการรู้จักและเข้าถึงบริการของเรา
                        <br></br> <br></br>
                        ข้อมูลที่ถูกจัดเก็บผ่านคุกกี้
                        <br></br> <br></br>
                        “คุกกี้” (Cookie) คือไฟล์เล็กๆ ที่โดนสร้างบนอุปกรณ์อินเตอร์เน็ตของผู้ใช้บริการเมื่อมาใช้เว็บไซต์ของ ScoutOut คุกกี้นี้จะเก็บข้อมูลการใช้บริการของผู้ใช้บริการ ซึ่งรวมถึงแต่ไม่จำกัดเพียง หน้าเว็บไซต์ต่างๆ ที่ดู ปุ่มต่างๆ ที่กด วันที่และเวลาในการใช้งาน ซึ่งเราอาจใช้ข้อมูลเหล่านี้ในการแสดงโฆษณาให้กับผู้ใช้บริการ ไม่ว่าในหรือนอกบริการของ ScoutOut
                        <br></br> <br></br>
                        ผู้ใช้บริการสามารถเลือกที่จะยอมรับ หรือปฏิเสธคุกกี้ ซึ่งผู้ใช้บริการสามารถเซ็ตผ่านบราวเซอร์ให้ปฏิเสธคุกกี้ นอกจากนี้ผู้ใช้บริการสามารถเลือกที่จะลบคุกกี้ และข้อมูลที่ถูกเก็บไว้ภายในคุกกี้นั้นๆ
                        <br></br> <br></br>
                        ข้อมูลที่ถูกจัดเก็บผ่านพิกเซล
                        <br></br> <br></br>
                        ScoutOut อาจทำการวางรูปภาพใสขนาดเล็ก (“พิกเซล”) ไว้ในเว็บไซต์ แอพพลิเคชั่น โฆษณา และ/หรือ อีเมล์ ซึ่งจะถูกใช้ในการดูสถิติการใช้งานของบริการต่างๆ ซึ่งรวมถึงแต่ไม่จำกัดเพียง การเปิดดูส่วนต่างๆ ของบริการ จำนวนหน้าที่ผู้ใช้บริการเห็น การเปิดอ่านอีเมล์ และโฆษณาที่ผู้ใช้บริการสนใจ
                        <br></br> <br></br>
                        ข้อมูลที่ถูกจัดเก็บผ่านเลขที่อุปกรณ์มือถือ
                        <br></br> <br></br>
                        เลขที่อุปกรณ์มือถือ (mobile device ID) และ/หรือ เลขที่ผู้รับโฆษณา (Advertising ID ในระบบปฏิบัติการ iOS) จะถูกเก็บในกรณีที่ผู้ใช้บริการใช้บริการ ScoutOut ผ่านแอพพลิเคชั่นบนมือถือ และ/หรือ แท็บเล็ต
                        <br></br> <br></br>
                        ซึ่งเลขที่อุปกรณ์มือถือเป็นเลขที่เฉพาะเจาะจงสำหรับมือถือของผู้ใช้บริการ และไม่สามารถลบได้ แต่ผู้ใช้บริการสามารถลบเลขที่ผู้รับโฆษณาได้ในเมนูตั้งค่าบนมือถือ iPhone หรือ iPad
                        <br></br> <br></br>
                        เลขที่เหล่านี้จะถูกใช้ในการประมวลสถิติเรื่องการใช้บริการแอพพลิเคชั่น การแสดงโฆษณา โฆษณาที่ผู้ใช้บริการสนใจ
                        <br></br> <br></br>
                        ข้อมูลที่ถูกจัดเก็บผ่านบุคคลที่สามเพื่อการโฆษณา
                        <br></br> <br></br>
                        ScoutOut อาจให้สิทธิ์แก่ผู้ให้บริการ บริษัทโฆษณา หรือเครือข่ายโฆษณา (ad networks) ที่เป็นบุคคลที่สาม ในการแสดงโฆษณาให้กับผู้ใช้บริการ
                        <br></br> <br></br>
                        ผู้ให้บริการเหล่านี้อาจมีการเก็บข้อมูลผู้ใช้บริการ ผ่านคุกกี้ พิกเซล หรือเลขที่ผู้รับโฆษณา ที่ผู้ใช้บริการเห็นหรือกดในโฆษณาที่แสดง ซึ่ง ScoutOut จะไม่ทำการเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการให้กับบุคคลที่สามเหล่านี้
                        เนื้อหาที่ผู้ใช้บริการเป็นผู้สร้าง (user generated content)
                        <br></br> <br></br>
                        ผู้ใช้บริการสามารถสมัครใจเขียน และ/หรือสร้าง ข้อความ บทความ โพสต์ โฆษณา หรือข้อมูลอื่นๆ ที่อาจเกี่ยวข้องกับผู้ใช้บริการเอง และ/หรือบุคคลอื่น บนหน้าเว็บไซต์และ/หรือบริการอื่นของ ScoutOut ซึ่งข้อมูลที่ถูกสร้างเหล่านี้ต้องไม่ละเมิดเงื่อนไขและข้อตกลงการใช้บริการของ ScoutOut
                        <br></br> <br></br>
                        ทั้งนี้หากมีการเปิดเผยข้อมูลส่วนบุคคลในเนื้อหาที่ถูกสร้างเหล่านี้บนหน้าเว็บไซต์ และ/หรือบริการที่ดูได้แบบสาธารณะ เนื้อหานั้นจะกลายเป็นข้อมูลสาธารณะโดยปริยาย และอาจถูกนำไปใช้โดยผู้ใดก็ได้ ScoutOut ไม่สามารถควบคุมการเข้าถึงเนื้อหานั้นๆ ได้ และจะไม่รับผิดชอบต่อผล ความสูญเสีย และ/หรือ ความเสียหายที่เกิดขึ้นจากการรั่วไหลของข้อมูลนั้นๆ ที่ผู้ใช้บริการสร้างขึ้นโดยสมัครใจ
                        <br></br> <br></br>
                        ความปลอดภัยของข้อมูล
                        <br></br> <br></br>
                        ScoutOut มีมาตรการความปลอดภัยในการรักษาความลับของผู้ใช้บริการเพื่อไม่ให้ข้อมูลรั่วไหลไปสู่บุคคลที่สามที่ไม่ได้ระบุไว้ในส่วนของ “การเปิดเผยหรือโอนข้อมูล” โดยการจำกัดการเข้าถึงฐานข้อมูล เท่าที่จำเป็น ให้กับผู้ใช้บริการอื่น และ บุคลากรที่ได้รับอนุญาตของ ScoutOut แต่ทั้งนี้ไม่มีระบบใดที่ปลอดภัยอย่างสมบูรณ์ ซึ่ง ScoutOut ไม่สามารถรับประกัน และผู้ใช้บริการไม่ควรคาดหวังว่า ข้อมูลของผู้ใช้บริการในที่สุดจะไม่รั่วไหลไปสู่บุคคลที่สาม และ บุคคลที่ไม่ได้รับอนุญาตจาก ScoutOut จะไม่สามารถเข้าถึงฐานข้อมูลได้
                        <br></br> <br></br>
                        ผู้ใช้บริการควรระมัดระวังในการเปิดเผย และ/หรือ ส่งข้อมูลส่วนบุคคล หรือข้อมูลที่ละเอียดอ่อน ผ่านระบบอีเมล และ/หรือ แช็ทที่ไม่น่าเชื่อถือ  และไม่ควรเปิดเผยรหัสผ่านบัญชีสมาชิกให้กับผู้อื่นไม่ว่าในกรณีใดก็ตาม
                        <br></br><br></br>
                        <span className="privacy-content-bold">การเปิดเผยหรือโอนข้อมูล</span>
                        <br></br> <br></br>
                        ScoutOut เคารพว่าข้อมูลส่วนบุคคล และข้อมูลอื่นๆ ของผู้ใช้บริการนั้นถือเป็นความลับ และจะดำเนินการรักษาความลับของข้อมูลโดยไม่เปิดเผยข้อมูลของผู้ใช้บริการให้กับบุคคลใด ยกเว้นในกรณีตามที่ได้ระบุไว้ด่านล่าง ทั้งนี้ ScoutOut ไม่สามารถควบคุมการใช้ข้อมูลของท่าน ซึ่งรวมถึงข้อมูลส่วนบุคคล ที่ได้ถูกเปิดเผยให้แก่ผู้หางานหรือผู้ว่าจ้างได้
                        <br></br> <br></br>
                        การเปิดเผยข้อมูลให้กับผู้ใช้บริการอื่น
                        <br></br> <br></br>
                        เมื่อผู้ใช้บริการลงทะเบียนบนเว็บไซต์หรือ กับบริการอื่นๆ ของ ScoutOut ผู้หางานอื่นอาจสามารถเห็นโปรไฟล์ของผู้ใช้บริการ ตามค่าความเป็นส่วนตัวที่ท่านตั้ง (privacy settings) ตามความสัมพันธ์ทางตรง เช่นเป็นเพื่อนกันในระบบของ ScoutOut Facebook LINE LinkedIn และ/หรือ Google หรือ ตามความสัมพันธ์ทางอ้อม ซึ่งรวมถึงแต่ไม่จำกัดเพียง มีเพื่อนที่เหมือนกัน และ/หรือ เคยทำงานที่เดียวกัน โดยผู้หางานอื่นจะสามารถเห็นข้อมูลใน โปรไฟล์ของผู้ใช้บริการ ซึ่งรวมถึงแต่ไม่จำกัดเพียง รูปโปรไฟล์ ชื่อ นามสกุล อายุ ระยะทางระหว่างผู้ใช้บริการกับผู้หางานอื่นรายนั้น ประวัติการทำงาน ประวัติการศึกษา ข้อความที่ผู้ใช้บริการได้เขียนไว้ และ ความสัมพันธ์ระหว่างผู้ใช้บริการกับผู้หางานอื่นรายนั้น
                        <br></br> <br></br>
                        การเปิดเผยข้อมูลให้กับผู้ว่าจ้าง
                        <br></br> <br></br>
                        เมื่อผู้หางานทำการสมัครงานที่ผู้ว่าจ้างลงโฆษณาในเว็บไซต์ หรือบริการอื่นๆของ ScoutOut ผู้หางานต้องยินยอมให้ ScoutOut เปิดเผยข้อมูลส่วนบุคคลของผู้หางานให้กับผู้ว่าจ้างในการสมัครแต่ละครั้ง ซึ่งผู้ว่าจ้าง จะสามารถเข้าถึงโปรไฟล์ของผู้หางาน ซึ่งรวมถึงแต่ไม่จำกัดเพียง รูปโปรไฟล์ ชื่อ นามสกุล ที่อยู่ อีเมล เบอร์โทรศัพท์ อายุ ประวัติการทำงาน ประวัติการศึกษา ข้อความที่ผู้หางานได้เขียนไว้ เรซูเม่ (resume) ข้อมูลประกอบใบสมัคร และข้อมูลในการติดต่ออื่นๆ สำหรับวัตถุประสงค์ในการส่งเสริมกิจกรรมที่เกี่ยวข้องกับการสรรหาบุคลากรเท่านั้น
                        <br></br> <br></br>
                        การเปิดเผยข้อมูลให้กับผู้ฝึกสอน
                        <br></br> <br></br>
                        เมื่อผู้หางานทำการลงทะเบียนในคอร์สเรียนของผู้ฝึกสอน ผู้หางานต้องยินยอมให้ ScoutOut เปิดเผยข้อมูลส่วนบุคคลของผู้หางานให้กับผู้ฝึกสอนในการลงทะเบียนแต่ละครั้ง ซึ่งผู้ฝึกสอน จะสามารถเข้าถึงโปรไฟล์ของผู้หางาน ซึ่งรวมถึงแต่ไม่จำกัดเพียง รูปโปรไฟล์ ชื่อ นามสกุล อายุ ประวัติการทำงาน ประวัติการศึกษา ข้อความที่ผู้หางานได้เขียนไว้ เรซูเม่ (resume) ข้อมูลการลงทะเบียน ความคิดเห็น คำขอ และข้อมูลในการติดต่ออื่นๆ สำหรับวัตถุประสงค์ในการพัฒนาสายอาชีพของผู้หางาน และการพัฒนาเนื้อหาและคอร์สพัฒนาทักษะทางอาชีพของผู้ฝึกสอนเท่านั้น
                        <br></br> <br></br>
                        การเปิดเผยข้อมูลผ่านบริการจัดหางานของ ScoutOut
                        <br></br> <br></br>
                        เมื่อผู้ใช้บริการลงทะเบียน ส่งเรซูเม่ (resume) หรือ ทำการสมัครงานบนบริการ ผู้หางานต้องยินยอมให้ ScoutOut ใช้ ประมวล เปิดเผยข้อมูลส่วนบุคคลของผู้หางานให้กับผู้ว่าจ้างหรือตำแหน่งที่เหมาะสมผ่านบริการจัดหางานของ ScoutOut ซึ่งผู้ว่าจ้างนั้น จะสามารถเข้าถึงโปรไฟล์ของผู้หางาน ซึ่งรวมถึงแต่ไม่จำกัดเพียง รูปโปรไฟล์ ชื่อ นามสกุล ที่อยู่ อีเมล เบอร์โทรศัพท์ อายุ ประวัติการทำงาน ประวัติการศึกษา ข้อความที่ผู้หางานได้เขียนไว้ เรซูเม่ (resume) ข้อมูลประกอบใบสมัคร และข้อมูลในการติดต่ออื่นๆ สำหรับวัตถุประสงค์ในการส่งเสริมกิจกรรมที่เกี่ยวข้องกับการสรรหาบุคลากรเท่านั้น
                        <br></br> <br></br>
                        การเปิดเผยข้อมูลให้กับผู้ให้บริการที่เป็นบุคคลที่สาม
                        <br></br> <br></br>
                        ScoutOut อาจมีการเปิดเผยข้อมูล ซึ่งรวมถึงแต่ไม่จำกัดเพียง ข้อมูลส่วนบุคคลและข้อมูลทางการเงินของผู้ใช้บริการ ให้กับผู้ให้บริการที่เป็นบุคคลที่สามเพื่อให้บริการ และ/หรือ ทำธุรกรรมแทน ScoutOut ซึ่งผู้ใช้บริการยินยอมเปิดเผยเผยข้อมูลให้ ScoutOut และ/หรือบุคคลที่สาม ในการให้บริการ และ/หรือทำธุรกรรมเหล่านี้ ซึ่งอาจรวมถึงแต่ไม่จำกัดเพียง การให้บริการทางการเงิน การให้บริการ customer service การให้บริการทางด้านการตลาด การทำธุรกิจ การวิเคราะห์ยอดขาย การประมวลสถิติการโฆษณา การตรวจสอบผู้ใช้บริการ การสนับสนุนเว็บไซต์และบริการของ ScoutOut และ การสนับสนุนการแข่งขัน ชิงโชค หรือสำรวจ
                        <br></br> <br></br>
                        ผู้ให้บริการที่เป็นบุคคลที่สามจะได้รับข้อมูลส่วนบุคคล และข้อมูลทางการเงิน ตามที่จำเป็นในการให้บริการ และ/หรือทำธุรกรรมแทน ScoutOut แต่ผู้ให้บริการเหล่านี้ไม่มีสิทธิ์ที่จะเปิดเผย หรือใช้ข้อมูลที่ได้เพื่อประโยชน์อื่นใด
                        <br></br> <br></br>
                        การเปิดเผยข้อมูลในวาระอื่นๆ
                        <br></br> <br></br>
                        ScoutOut อาจมีการเปิดเผยข้อมูลของผู้ใช้บริการ ซึ่งรวมไปถึงข้อมูลส่วนบุคคล และข้อมูลทางการเงิน ในวาระอื่นๆ ดังต่อไปนี้
                        <br></br> <br></br>
                        เมื่อมี
                        หมายเรียก หมายค้น หมายศาล และ/หรือ
                        หนังสือหรือคำขอความร่วมมือจากหน่วยงานบังคับใช้กฎหมาย หรือหน่วยงานอื่นๆ ของรัฐ และ/หรือ
                        การกำหนดไว้ในกฎหมาย และ/หรือ
                        การคุ้มครอง ใช้ และปกป้องสิทธิ์ตามกฎหมายของ ScoutOut
                        ในกรณีเหล่านี้ ScoutOut ขอสงวนสิทธิ์การตัดสินใจใน การใช้หรือไม่ใช้สิทธิ์ การคัดค้านหรือไม่คัดค้าน หมาย คำขอ ข้อกฏหมาย และ/หรือ สิทธิ์ตามกฏหมายใดๆ
                        <br></br> <br></br>
                        เมื่อ ScoutOut เห็นสมควรว่าข้อมูลที่เปิดเผยเกี่ยวข้องกับ
                        การสืบสวนสอบสวน การป้องกัน หรือการอื่นๆ ที่สืบเนื่องจากการกระทำที่ผิดกฎหมาย เป็นการหลอกลวง หรือการกระทำผิดอื่นๆ และ/หรือ
                        การปกป้องสิทธิ์ ทรัพย์สิน ความปลอดภัย ของบริษัท ผู้ใช้บริการ พนักงาน หรือบุคคลอื่นๆ และ/หรือ
                        การกระทำตามกฎหมาย หรือการให้ความร่วมมือกับหน่วยงานบังคับใช้กฎหมาย และ/หรือ
                        การบังคับใช้เงื่อนไขและข้อตกลงการใช้บริการ ข้อตกลงอื่นๆ และนโยบายอื่นๆ ของ ScoutOut
                        ในการทำธุรกรรมที่สำคัญๆ ขององค์กร (substantial corporate transaction) เช่นการขายกิจการ การถอนทุน การควบรวมกิจการ การรวมธุรกิจ การขายสินทรัพย์ หรือแม้แต่ในการล้มละลาย
                        ข้อมูลที่ผ่านการประมวล และข้อมูลที่ไม่สามารถระบุตัวบุคคล
                        ScoutOut สามารถเข้าถึง เก็บ ใช้ และเปิดเผยข้อมูลที่ไม่สามารถใช้ระบุตัวบุคคล หรือข้อมูลที่ผ่านการประมวลผลแล้ว ได้ในทุกกรณีตามที่ระบุไว้ด้านบน ซึ่งอาจรวมไปถึงการเปิดเผยข้อมูลให้ผู้บริการที่เป็นบุคคลที่สามในการพัฒนา แสดง เก็บสถิติ และ/หรือเก็บผลการโฆษณา ไม่ว่าในหรือนอกเว็บไซต์ หรือบริการของ ScoutOut โดยเรามีสิทธิ์ทำการรวมข้อมูลที่ไม่สามารถระบุตัวบุคคลของ ScoutOut เข้ากับข้อมูลที่ไม่สามารถระบุตัวบุคคลจากแหล่งอื่นๆ และ มีสิทธิ์ในการเปิดเผยข้อมูลที่ผ่านการประมวล ข้อมูลที่ไม่สามารถระบุตัวบุคคล หรือข้อมูลส่วนบุคคลที่ผ่านการเข้ารหัส ให้กับบุคคลที่สาม ซึ่งรวมถึงแต่ไม่จำกัดเพียง ที่ปรึกษา ผู้ลงโฆษณา และผู้ลงทุน ในการวิเคราะห์และ ดำเนินธุรกิจ
                        <br></br> <br></br>
                        <span className="privacy-content-bold">การเข้าถึงหรือการขอแก้ไขข้อมูล</span>
                        <br></br> <br></br>
                        ในกรณีที่ผู้ใช้บริการมีบัญชีสมาชิกของ ScoutOut ผู้ใช้บริการสามารถตรวจทาน แก้ไข เพิ่มเติมข้อมูลส่วนบุคคล ของผู้ใช้บริการในบริการได้เอง และสามารถขอเข้าถึงข้อมูลส่วนบุคคลทั้งหมด หรือขอลบบัญชีสมาชิก โดยติดต่อมาที่แผนกบริการลูกค้าของ ScoutOut ตามข้อมูลด้านล่างในส่วน “การสอบถาม” แต่ทั้งนี้ระยะเวลาในการเก็บข้อมูลของท่านในบริการจะทำตามความเหมาะสมและความจำเป็น
                        <br></br> <br></br>
                        ในกรณีการลบบัญชีสมาชิก ScoutOut ขอสงวนสิทธิ์ในการ
                        <br></br> <br></br>
                        เก็บข้อมูลที่ไม่สามารถระบุตัวบุคคล
                        ข้อมูลที่ผ่านการประมวล และ
                        ในบางกรณีที่จำเป็น ข้อมูลอาจไม่ถูกลบออก เช่น เพื่อการป้องกันการหลอกลวง เพื่อตรวจสอบการบังคับใช้เงื่อนไขและข้อตกลงการให้บริการ เพื่อไม่ให้มีผลกระทบในเชิงระบบหรือกับผู้ใช้บริการอื่นๆ หรือเพื่อทำตามที่กฎหมายกำหนด
                        และ หากข้อมูลที่ผู้ใช้บริการต้องการลบได้ถูกส่งต่อไปยังบุคคลที่สามก่อนแล้ว ตามที่ได้ระบุไว้ในนโยบายความเป็นส่วนตัวฉบับนี้ การเก็บข้อมูลนี้ของบุคคลที่สามจะเป็นไปตามนโยบายความเป็นส่วนตัวของบุคคลที่สามแทน
                        <br></br> <br></br>
                        <span className="privacy-content-bold">ลิงก์ไปยังเว็บไซต์ของบุคคลที่สาม</span>
                        <br></br> <br></br>
                        ในเว็บไซต์และบริการของ ScoutOut อาจมีปุ่มหรือลิงค์ ที่นำผู้ใช้บริการไปยังเว็บไซต์ของบุคคลที่สาม ซึ่งไม่ได้ใช้นโยบายความเป็นส่วนตัวฉบับนี้ เช่นเมื่อผู้ใช้บริการกดบนโฆษณา ผู้ใช้บริการอาจถูกนำไปยังเว็บไซต์ของบุคคลที่สาม ซึ่งอาจมีการเก็บข้อมูล รวมถึงข้อมูลส่วนบุคคลของผู้ใช้บริการ ScoutOut ขอแนะนำให้ผู้ใช้บริการอ่านนโยบายความเป็นส่วนตัวในเว็บไซต์ของบุคคลที่สามทุกครั้งก่อนใช้บริการ โดยมองหาปุ่มความเป็นส่วนตัว (“Privacy” or “Privacy Policy”) ซึ่งปกติอยู่ด้านล่างของหน้าเว็บไซต์
                        <br></br> <br></br>
                        <span className="privacy-content-bold"> คำแปล</span>
                        <br></br> <br></br>
                        กรณีที่ ScoutOut ได้จัดทำคำแปลนโยบายความเป็นส่วนตัวให้แก่ผู้ใช้งาน (“คำแปล”) จากนโยบายความเป็นส่วนตัวฉบับภาษาอังกฤษ (“ฉบับภาษาอังกฤษ”)  ท่านยินยอมให้ ScoutOut ใช้ฉบับภาษาอังกฤษเป็นหลัก และในกรณีที่มีข้อขัดแย้งระหว่างฉบับภาษาอังกฤษกับคำแปล ท่านยินยอมให้ข้อกำหนดในฉบับภาษาอังกฤษใช้บังคับเหนือคำแปลอื่นใด
                        <br></br> <br></br>
                        <span className="privacy-content-bold">การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</span>
                        <br></br> <br></br>
                        นโยบายความเป็นส่วนตัวนี้ อาจมีการปรับปรุง แก้ไข เปลี่ยนแปลง เป็นครั้งคราว ตามที่ ScoutOut พิจารณาว่าเหมาะสมตามการเปลี่ยนแปลงของ กฏหมายที่เกี่ยวข้อง การให้บริการ พันธมิตรทางธุรกิจ และ/หรือ เทคโนโลยี
                        <br></br> <br></br>
                        เมื่อนโยบายความเป็นส่วนตัวมีการแก้ไขเราจะระบุ เลขเวอร์ชั่น (version) และ วันที่เริ่มบังคับใช้ไว้ที่ด้านบนของนโยบายความเป็นส่วนตัวนี้ และถ้ามีการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวอย่างมีนัยสำคัญ ScoutOut จะทำการแจ้งให้ผู้ใช้บริการทราบผ่านวิธีการที่เหมาะสม ซึ่งอาจรวมถึงการแจ้งเตือน ผ่านระบบแจ้งเตือนบนมือถือ หรือ อีเมล
                        <br></br> <br></br>
                        เราแนะนำให้ผู้ใช้บริการอ่านนโยบายความเป็นส่วนตัวนี้อย่างละเอียด และ การใช้เว็บไซต์ หรือบริการของ ScoutOut หลังจากมีการบังคับใช้นโยบายความเป็นส่วนตัวเวอร์ชั่นใหม่ ถือว่าเป็นการยอมรับในความเปลี่ยนแปลงของนโยบายความเป็นส่วนตัวฉบับใหม่ หรือหากผู้ใช้บริการไม่ยอมรับในในความเปลี่ยนแปลงของนโยบายความเป็นส่วน ผู้ใช้บริการสามารถติดต่อขอลบบัญชีสมาชิกได้ตามข้อมูลในส่วน “การเข้าถึงหรือการขอแก้ไขข้อมูล”
                        <br></br> <br></br>
                        <span className="privacy-content-bold">การสอบถาม และข้อมูลติดต่อ</span>
                        <br></br>
                        หากท่านมีข้อสอบถามใดที่เกี่ยวข้องกับนโยบายความเป็นส่วนตัวนี้ ท่านสามารถติดต่อเราได้ที่
                        <br></br> <br></br>
                        <br></br> <br></br>
                        <span className="privacy-content-bold">บริษัท จัดหางาน สเกาท์ เอาท์ จำกัด</span><br></br>
                        เลขที่ 597/5 ชั้น 2 ห้อง 204<br></br>
                        ถนนสุขุมวิท แขวงคลองตันเหนือ<br></br>
                        เขตวัฒนา กรุงเทพมหานคร 10110<br></br>
                        เบอร์โทรศัพท์: 02-038-2639<br></br>
                        อีเมล: <a href="mailto:privacy-dpo@scoutout.net">privacy-dpo@scoutout.net</a>
                        <br></br> <br></br>


                    </span>
                </div>
                
                
            </div>
            <TalentSauceFooterComponent></TalentSauceFooterComponent>
        </>
    )
}

export default PrivacyComponent;