import { Button, Modal } from "react-bootstrap";
import "./candidate.modal.scss";
import TOPUP from "../assets/images/top-up.png";
import { useContext, useEffect, useState } from "react";
import UtilityService from "../utils/utilities";
import GAConfig from "../ga-config";
import ProfileSCModel from "../model/profile-sc.model";
import { CandidateExperience, CandidateResultModel, Resume } from "../model/talent-search/candidate-result.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faStar, faUser } from "@fortawesome/free-solid-svg-icons";
import languageConfig from "../language-config";
import { CandidateHistoryModel } from "../model/talent-search/candidate-history.model";
import { CircularProgress, Divider } from "@mui/material";
import { ProviderContext } from "../context/provider.context";
import templateConfig from "../template-config";
import moment from "moment";
import GPTService from "../services/gpt.service";
import TalentSearchService from "../services/talentsearch.service";
import { faStar as faStarActive } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarDefault } from "@fortawesome/free-regular-svg-icons";
import config from "../config";

const CandidateModal = ({ open, handleClose, id, profileModel, isHistory, candidateModel, openSuccess }: any) => {
    const { creditModel, setCreditModel }: any = useContext(ProviderContext)
    const [idCandidate, setIdCandidate] = useState(id);
    const [isLoading, setIsLoading] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const [candidateMod, setCandidateModel] = useState<CandidateResultModel>();
    const [bookmarkModel, setBookmarkModel] = useState<Resume[]>();
    const [isFailed, setIsFailed] = useState(false);
    const [isLoadingBookmark, setIsLoadingBookmark] = useState(false);
    const [sourceKey, setSourceKey] = useState("");
    const sourceKeyList = {
        external: "pjRBasXbRFGKwxpzya",
        internal: "DHMBvD4P28eCYKeeCw",
        tech: "jbQziHClw71HwuDZwH"
    };
    const whitelist = {
        external: ["@baycoms.com", "@beryl8.com", "@inspex.co", "@maqe.com", "@techsauce.co", "@extend-it-resource.com"],
        internal: ["@scoutout"]
    };

    useEffect(() => {
        if (profileMod) {
            if (isHistory) {
                if (candidateModel) {
                    setCandidateModel(candidateModel);
                } else {
                    getHistoryTalentSearch(idCandidate)
                }

            } else {
                getCandidateContact();
            }
        }
    }, [profileMod])

    useEffect(() => {
        if(bookmarkModel){
            if(candidateMod){
                const candidateEdit = { ...candidateMod };
                setIsLoadingBookmark(false);
                bookmarkModel.map((item) => {
    
                    if (item.candidate_id == candidateMod.resume.candidate_id) {
                        candidateEdit.resume.is_bookmark = true;
                        setCandidateModel(candidateEdit);
                    }
                })
            }
            
        }else{
            getBookmark();
        }
    },[bookmarkModel])

    useEffect(() => {
        if (candidateMod && !bookmarkModel) {
            
        }
    }, [candidateMod])
    useEffect(() => {
        if (idCandidate) {

        }
    }, [idCandidate])

    const saveBookmark = () => {
        if(candidateMod){
            TalentSearchService.saveFavorite(sourceKey, candidateMod.resume.candidate_id).then((res) => {
                if(res.status == 200){
                    const candidateEdit = { ...candidateMod };
                    candidateEdit.resume.is_bookmark = true;
                    setCandidateModel(candidateEdit);
                }
            })
        }
    }

    const deleteBookmark = () => {
        if(candidateMod){
            TalentSearchService.deleteFavorite(sourceKey, candidateMod.resume.candidate_id).then((res) => {
                if(res.status == 200){
                    const candidateEdit = { ...candidateMod };
                    candidateEdit.resume.is_bookmark = false;
                    setCandidateModel(candidateEdit);
                }
            })
        }
    }

    const getBookmark = () => {
        let source_key = "";
        if (config.app_name === "TALENTSAUCE") {
            source_key = sourceKeyList.tech
        } else {
            const domainArrayExternal = whitelist.external;
            const domainArrayInternal = whitelist.internal;
            domainArrayExternal.map((item: string, index: number) => {
                if (profileMod.email.includes(item)) {
                    source_key = sourceKeyList.external
                }
            })
            domainArrayInternal.map((item: string, index: number) => {
                if (profileMod.email.includes(item)) {
                    source_key = sourceKeyList.internal
                }
            })
        }
        setSourceKey(source_key);
        setIsLoadingBookmark(true);
        TalentSearchService.getFavorite(source_key).then((res) => {

            if (res.data ) {
                setBookmarkModel(res.data);
            }

        }).catch((err) => {
            setIsLoadingBookmark(false);
        })
    }
    const getCandidateContact = () => {
        setIsLoading(true);

        TalentSearchService.postCandidate(idCandidate).then((res) => {
            console.log(res);
            if (typeof res.data === 'object') {
                setIsLoading(false);
                setCandidateModel(res.data);
                UtilityService().clickSendEvent("open_candidate_success", "click");
                GPTService.getCredit().then((res3) => {

                    if (res3) {
                        openSuccess(idCandidate)
                        setCreditModel(res3.data)
                    }

                })
            } else {
                if (res.status == 400) {
                    setIsLoading(false);
                    setIsFailed(true);
                    console.log(res);
                    UtilityService().clickSendEvent("error_candidate_400", "click");
                } else if (res.status == 201) {
                    setIsLoading(false);
                    UtilityService().clickSendEvent("existing_open_candidate", "click");
                    getHistoryTalentSearch(idCandidate)
                }
            }

        }).catch((err) => {
            console.log(err);
            if (err.response && err.response.status == 400 && err.response.data.error == "already used credit") {
                setIsLoading(false);
                UtilityService().clickSendEvent("existing_open_candidate", "click");
                getHistoryTalentSearch(idCandidate)
            } else if (err.response && err.response.status == 201) {
                setIsLoading(false);
                UtilityService().clickSendEvent("existing_open_candidate", "click");
                getHistoryTalentSearch(idCandidate)
            } else {
                setIsLoading(false);
                setIsFailed(true);
                UtilityService().clickSendEvent("error_candidate_400", "click");
            }

        })
    }
    const getHistoryTalentSearch = (id) => {
        setIsLoading(true);
        TalentSearchService.getHistory().then((res) => {
            setIsLoading(false);
            if (res) {
                const candidateResult = res.data as CandidateHistoryModel[];
                if (candidateResult && candidateResult.length > 0) {
                    const filterData = candidateResult.filter(item => item.candidate_id == id)
                    if (filterData && filterData.length > 0) {
                        setCandidateModel(filterData[0].log_data)
                    }
                } else {
                    setIsFailed(true);
                }


            }

        })
    }

    const reformat = (value) => {
        if (value) {
            const formattedHtmlString = value.replace(/\n/g, '<br>');
            return formattedHtmlString;
        } else {
            return "";
        }
    }
    const clickTel = () => {
        UtilityService().clickSendEvent("tel_topup_to_admin" , "click")
        window.open("tel:020382639");
    }
    const convertGMT = (dateString, isTime?) => {
        // Create a Date object from the timestamp
        // Create a Date object from the timestamp
        const date = new Date(dateString);
        // Add 7 hours to the date (1 hour = 60 minutes = 3600 seconds = 3600000 milliseconds)
        const dateInGMT7 = new Date(date.getTime() + (7 * 3600000));
        if (isTime) {
            const formatDateString = moment(dateInGMT7).format('DD/MM/YYYY HH:mm');
            return formatDateString;
        } else {
            const formatDateString = moment(dateInGMT7).format('DD/MM/YYYY');
            return formatDateString;
        }
    }
    const convertGMTOnlyMonth = (dateString) => {
        // Create a Date object from the timestamp
        // Create a Date object from the timestamp
        const date = new Date(dateString);
        // Add 7 hours to the date (1 hour = 60 minutes = 3600 seconds = 3600000 milliseconds)
        const dateInGMT7 = new Date(date.getTime() + (7 * 3600000));

        const formatDateString = moment(dateInGMT7).format('MM/YYYY');
        return formatDateString;
    }
    const clickEmail = () => {
        UtilityService().clickSendEvent("email_topup_admin" , "click")
        window.open("mailto:customerservice@scoutout.net?subject=" + templateConfig.text.contact_email);
    }
    function formatThaiPhoneNumber(phoneNumber) {
        // Remove all non-digit characters from the input
        const cleanedNumber = phoneNumber.replace(/\D/g, '');

        // Check if the cleaned number starts with "0" and has the expected length
        if (/^0\d{8,9}$/.test(cleanedNumber)) {
            // Determine the position to insert hyphens based on the length
            const insertPosition = cleanedNumber.length === 10 ? 3 : 2;

            // Insert hyphens to format the number
            const formattedNumber =
                cleanedNumber.slice(0, insertPosition) +
                '-' +
                cleanedNumber.slice(insertPosition, insertPosition + 3) +
                '-' +
                cleanedNumber.slice(insertPosition + 3);

            return formattedNumber;
        } else {
            // Invalid phone number
            return 'Invalid phone number';
        }
    }

    const viewResume = (item) => {
        const userAgent = window.navigator.userAgent;
        const isChrome = userAgent.indexOf('Chrome') > -1;
        const isSafari = userAgent.indexOf('Safari') > -1;

        console.log(item);
        const candidateItem = item as CandidateResultModel
        TalentSearchService.downloadResume(candidateItem.resume.bucket, candidateItem.resume.key, candidateItem.resume.candidate_id, profileMod.email).then((res) => {
            if (res) {
                UtilityService().clickSendEvent("view_resume", "click")
                if (isSafari) {
                    downloadPdf(res.data.resume_url, "download-resume-" + candidateItem.resume.candidate_id);
                } else {
                    const newWindow = window.open(res.data.resume_url, '_blank');
                    if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
                        // Popup blocked
                        alert('Popup blocked. Please allow popups for this website.');
                    }
                }

            }

        })
    }
    const downloadPdf = (url, fileName) => {
        // Create a new anchor element
        const anchor = document.createElement('a');

        // Set the href to the URL of the PDF
        anchor.href = url;

        // Optionally set the download attribute to a filename
        anchor.download = fileName || 'download.pdf';

        // Append the anchor to the body
        document.body.appendChild(anchor);

        // Trigger a click on the anchor
        anchor.click();

        // Remove the anchor from the body
        document.body.removeChild(anchor);
    }
    return (

        <div className="candidate-modal-main">
            <Modal show={open} onHide={handleClose} className="candidate-modal-main" >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="">
                    {
                        isLoading && !isFailed &&

                        <div className="candidate-loading-control">
                            <div className="candidate-loading-progress-control">
                                <CircularProgress className="candidate-loading-progress"></CircularProgress>
                                <span className="candidate-loading-text">กำลังโหลด</span>

                            </div>
                        </div>

                    }
                    {
                        isFailed &&

                        <div className="candidate-error-control">
                            <FontAwesomeIcon icon={faCircleExclamation} className="candidate-error-icon"></FontAwesomeIcon>
                            <span className="candidate-error-text">เครดิตไม่พอ</span>
                            <Divider className="candidate-error-divider"></Divider>
                            <div className="topup-modal">
                                <div className="topup-control">
                                    <img src={TOPUP} className="topup-img"></img>
                                    <span className="topup-text">หากคุณต้องการเติมเครดิตหรือมีคำถามเกี่ยวกับบริการของเรา</span>
                                    <span className="topup-text">กรุณาติดต่อเราที่หมายเลขโทรศัพท์ <span className="topup-link" onClick={clickTel}>02-038-2639</span></span>
                                    <span className="topup-text">หรือ อีเมล <span className="topup-link" onClick={clickEmail}>customerservice@scoutout.net</span></span>
                                    <br>
                                    </br>
                                    <span className="topup-text">ทีมงานของเรายินดีที่จะช่วยเสมอครับ</span>
                                </div>
                            </div>
                            <span className="candidate-error-close" onClick={handleClose}>ปิด</span>
                        </div>
                    }
                    {
                        (candidateMod && !isLoading && !isFailed) && <div className="candidate-modal-detail-control">
                            <div className="canidate-profile-control">
                                <div className="candidate-top-profile-control">
                                    <div className="candidate-profile-candidate-id-control">
                                        <span className="candidate-profile-candidate-id">
                                            ID: {candidateMod.resume.candidate_id}
                                        </span>
                                        {
                                            isLoadingBookmark ?

                                                <>
                                                <CircularProgress size="1rem" className="candidate-modal-bookmark-loading"></CircularProgress>
                                                </> :
                                                <FontAwesomeIcon 
                                                icon={candidateMod.resume.is_bookmark ? faStarActive : faStarDefault} 
                                                className="candidate-profile-candidate-icon"
                                                onClick={candidateMod.resume.is_bookmark ? () => deleteBookmark() : () => saveBookmark()}
                                                ></FontAwesomeIcon>
                                        }

                                    </div>

                                    <span className="candidate-profile-last-update">
                                        อัปเดตล่าสุด: {convertGMT(candidateMod.resume.last_modified)}
                                    </span>
                                </div>

                                {
                                    candidateMod.resume.profile_image_url ? <img src={candidateMod.resume.profile_image_url} className="candidate-profile-img"></img>
                                        :
                                        <FontAwesomeIcon icon={faUser} className="candidate-profile-img-default"></FontAwesomeIcon>
                                }
                                <span className="candidate-profile-name">{candidateMod.resume.first_name} {candidateMod.resume.last_name}</span>
                                {candidateMod.resume.email
                                    && <span className="candidate-profile-desc">{candidateMod.resume.email}</span>
                                }
                                {candidateMod.resume.phone
                                    && <span className="candidate-profile-desc">{candidateMod.resume.phone}</span>
                                }
                                {candidateMod.resume.date_of_birth
                                    && <span className="candidate-profile-desc">วันเกิด: {convertGMT(candidateMod.resume.date_of_birth, false)}</span>
                                }
                                <Button className="candidate-view-resume-button"
                                    onClick={() => viewResume(candidateMod)}
                                >ดู Resume</Button>
                            </div>
                            <span className="candidate-view-title">{languageConfig.candidate_profile.history_exp}</span>

                            {
                                candidateMod.candidate_experiences.length > 0 ?
                                    candidateMod.candidate_experiences.map((item: CandidateExperience, index: number) => {

                                        return (
                                            <div className="candidate-info-timeline-control" key={index}>
                                                <div className="candidate-info-timeline-line-control">
                                                    <div className="candidate-info-timeline-dot"></div>
                                                    {
                                                        index != candidateMod.candidate_experiences.length - 1 && <div className="candidate-info-timeline-line-vertical"></div>
                                                    }

                                                </div>
                                                <div className="candidate-info-timeline-desc-control">
                                                    <span className="candidate-info-timeline-desc-header">{item.work_experience_job_title} ที่ {item.work_experience_company_name}</span>

                                                    <div className="candidate-info-timeline-desc-info-control">
                                                        <span className="candidate-info-timeline-desc-info-title">วันที่เริ่มงาน: {convertGMTOnlyMonth(item.work_experience_start_date)} - {
                                                            item.work_experience_still_work_here ? "ปัจจุบัน" : item.work_experience_end_date && convertGMTOnlyMonth(item.work_experience_end_date)
                                                        }</span>
                                                        <span className="candidate-info-timeline-desc-info-title">
                                                            <span dangerouslySetInnerHTML={{ __html: reformat(item.work_experience_responsibilities) }}></span>
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <div className="candidate-info-control">
                                        <div className="candidate-info-control-flex">
                                            <span className="candidate-info-control-text">ไม่มีข้อมูล</span>
                                        </div>
                                    </div>
                            }
                        </div>
                    }



                </Modal.Body>


            </Modal>
        </div>
    )
}

export default CandidateModal;