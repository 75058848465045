const local = {
    base_url: "http://localhost:4200/",
    articles: {
        base_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator",
        recommend: "/recommend",
        view_url: "https://blog.scoutout.co/"
    },
    indexFile: "./index.prod.tsx",
    ga_4: "G-P9EXXW4209",
    ga: "",
    app_name: "HR9CLINIC",
    origin_talent_search : "https://talent-search-dev.web.app",
    content_gen_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator/",
    authen: {
        sso_url : "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id : "SCOUT_CONNECT",
        redirect_uri : "http://localhost:4200/checking",
        redirect_uri_interview : "http://localhost:4200/interview-question",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://leeladev.scoutout.net",
        }
    },
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/gpt-interview-question",
        resume_checker: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
            base_url: "https://dprod-api.scoutout.net/resume-checker"
        },
        upload_resume: {
            type: "hr_clinic",
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        },
    },
    crm:{
        is_show: true,
        base_url: "https://testlambda.scoutout.net:444/cms",
        sheet_id: "168Tzl95BtB0yU5mcjlz5xsZ1YpaOijqrMUD06EB_tew"
    },
  
    url:{
        scoutconnect_choose_company: "https://d3e51han25shup.cloudfront.net/home/choose-company",
    },
    talentSeach: {
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },
    app: {
        one_profile: "https://resume-landing-test.web.app/",
        talent_search: "https://talent-search-dev.web.app/preview",
        talent_search_bookmark: "https://talent-search-dev.web.app/bookmarks",
        home: "http://localhost:4200/",
        why: "http://localhost:4200#whyhr9clinic",
        features: "http://localhost:4200#features",
        hr9_services: "https://d3e51han25shup.cloudfront.net",
        hr9_landing: "https://scoutout.co/hr9/detail?utm_source=HR9CLINIC"
    },
    
};


const dev = {
    base_url: "https://hr-clinic-dev.web.app/",
    articles: {
        base_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator",
        recommend: "/recommend",
        view_url: "https://blog.scoutout.co/"
    },
    indexFile: "./index.prod.tsx",
    ga_4: "G-P9EXXW4209",
    ga: "",
    app_name: "HR9CLINIC",
    origin_talent_search : "https://talent-search-dev.web.app",
    content_gen_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator/",
    authen: {
        sso_url : "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id : "SCOUT_CONNECT",
        redirect_uri : "https://hr-clinic-dev.web.app/checking",
        redirect_uri_interview : "https://hr-clinic-dev.web.app",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://leeladev.scoutout.net",
        }
    },
  
   
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/gpt-interview-question",
        resume_checker: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
            base_url: "https://dprod-api.scoutout.net/resume-checker"
        },
        upload_resume: {
            type: "hr_clinic",
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        },
    },
   
    url:{
        scoutconnect_choose_company: "https://d3e51han25shup.cloudfront.net/home/choose-company",
    },
    crm:{
        is_show: false,
        base_url: "https://testlambda.scoutout.net:444/cms",
        sheet_id: "168Tzl95BtB0yU5mcjlz5xsZ1YpaOijqrMUD06EB_tew"
    },
    talentSeach: {
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },
    app: {
        one_profile: "https://resume-landing-test.web.app/",
        talent_search: "https://talent-search-dev.web.app/preview",
        talent_search_bookmark: "https://talent-search-dev.web.app/bookmarks",
        home: "https://hr-clinic-dev.web.app/",
        why: "https://hr-clinic-dev.web.app/#whyhr9clinic",
        features: "https://hr-clinic-dev.web.app/#features",
        hr9_services: "https://d3e51han25shup.cloudfront.net",
        hr9_landing: "https://scoutout.co/hr9/detail?utm_source=HR9CLINIC"
    }
};
const test = {
    base_url: "https://hr-clinic-test-so.web.app/",
    articles: {
        base_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator",
        recommend: "/recommend",
        view_url: "https://blog.scoutout.co/"
    },
    indexFile: "./index.prod.tsx",
    ga_4: "G-P9EXXW4209",
    ga: "",
    app_name: "HR9CLINIC",
    origin_talent_search : "https://talent-search-dev.web.app",
    content_gen_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator/",
    authen: {
        sso_url : "https://d2zjecrqpgwbzj.cloudfront.net",
        client_id : "SCOUT_CONNECT",
        redirect_uri : "https://hr-clinic-test-so.web.app/checking",
        redirect_uri_interview : "https://hr-clinic-test-so.web.app/interview-question",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysatest.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://leelatest.scoutout.net",
        }
    },
    
   
    chat_gpt: {
        base_url: "https://dprod-api.scoutout.net/generative-ai",
        resume_checker: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
            base_url: "https://dprod-api.scoutout.net/resume-checker"
        },upload_resume: {
            type: "hr_clinic",
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        },
    },
  
    url:{
        scoutconnect_choose_company: "https://d3e51han25shup.cloudfront.net/home/choose-company",
    },
    crm:{
        is_show: false,
        base_url: "https://testlambda.scoutout.net:444/cms",
        sheet_id: "168Tzl95BtB0yU5mcjlz5xsZ1YpaOijqrMUD06EB_tew"

    },
    talentSeach: {
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },
    app: {
        one_profile: "https://resume-landing-test.web.app/",
        talent_search: "https://talent-search-dev.web.app",
        talent_search_bookmark: "https://talent-search-dev.web.app/bookmarks",
        home: "https://hr-clinic-dev.web.app/",
        why: "https://hr-clinic-dev.web.app/#whyhr9clinic",
        features: "https://hr-clinic-dev.web.app/#features",
        hr9_services: "https://d3e51han25shup.cloudfront.net",
        hr9_landing: "https://scoutout.co/hr9/detail?utm_source=HR9CLINIC"
    }
};
const prod = {
    base_url: "https://hr9-clinic.scoutout.co/",
    articles: {
        base_url: "https://asia-southeast1-scoutout-mang-prod.cloudfunctions.net/contentgenerator",
        recommend: "/recommend",
        view_url: "https://blog.scoutout.co/"
    },
    indexFile: "./index.prod.tsx",
    ga_4: "G-KV9B0J13N5",
    ga: "",
    app_name: "HR9CLINIC",
    origin_talent_search : "https://talent.scoutout.co",
    content_gen_url: "https://asia-southeast1-scoutout-mang-prod.cloudfunctions.net/contentgenerator/",
    authen: {
        sso_url : " https://account.scoutout.co",
        client_id : "SCOUT_CONNECT",
        redirect_uri : "https://hr9-clinic.scoutout.co/checking",
        redirect_uri_interview : "https://hr9-clinic.scoutout.co/interview-question",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://account-api.scoutoutapi.com",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://connect-api.scoutoutapi.com",
        }
    },
    
   
    chat_gpt: {
        base_url: "https://dprod-api.scoutout.net/generative-ai",
        resume_checker: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
            base_url: "https://dprod-api.scoutout.net/resume-checker"
        },
        upload_resume: {
            type: "hr_clinic",
            x_api_key: "0kz74WhgVda2JBHCGu7Qd3XSrhgRL5az146oon4w",
            base_url : "https://f3clcckfr7.execute-api.ap-southeast-1.amazonaws.com/production"
        },
    },
   
    url:{
        scoutconnect_choose_company: "https://connect.scoutout.co/home/choose-company",
    },
    crm:{
        is_show: false,
        base_url: "https://dprod-api.scoutout.net/cms",
        sheet_id: "168Tzl95BtB0yU5mcjlz5xsZ1YpaOijqrMUD06EB_tew"
    },
    talentSeach: {
        x_api_key: "1TUCk4v9nYaKrYQqoELs7aEaiXXbuis91jbjiEw1",
        base_url: "https://bbyxui1fsg.execute-api.ap-southeast-1.amazonaws.com/production",
    },
    app: {
        one_profile: "https://resume-landing-test.web.app/",
        talent_search: "https://talent.scoutout.co/preview",
        talent_search_bookmark: "https://talent.scoutout.co/bookmarks",
        home: "https://hr9-clinic.scoutout.co/",
        why: "https://hr9-clinic.scoutout.co/#whyhr9clinic",
        features: "https://hr9-clinic.scoutout.co/#features",
        hr9_services: "https://hr9.scoutout.co",
        hr9_landing: "https://scoutout.co/hr9/detail?utm_source=HR9CLINIC"
    }
};
const ts_local = {
    base_url: "http://localhost:4200/",
    articles: {
        base_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator",
        recommend: "/recommend",
        view_url: "https://blog.talentsauce.co/"
    },
    indexFile: "./index.ts.tsx",
    ga_4: "G-JYX6KBX3WK",
    ga: "",
    app_name: "TALENTSAUCE",
    origin_talent_search : "https://talent-search-dev.web.app",
    content_gen_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator/",
    authen: {
        sso_url : "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id : "TALENTSAUCE",
        redirect_uri : "http://localhost:4200/checking",
        redirect_uri_interview : "http://localhost:4200/interview-question",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://leeladev.scoutout.net",
            
        }
    },
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/talent-sauce",
        resume_checker: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
            base_url: "https://dprod-api.scoutout.net/resume-checker"
        },
        upload_resume: {
            type: "talent_sauce",
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        },
    },
    crm:{
        is_show: true,
        base_url: "https://testlambda.scoutout.net:444/cms",
        sheet_id: "168Tzl95BtB0yU5mcjlz5xsZ1YpaOijqrMUD06EB_tew"
    },
  
    url:{
        scoutconnect_choose_company: "https://d3e51han25shup.cloudfront.net/home/choose-company",
    },
    talentSeach: {
        base_url: "https://testlambda.scoutout.net:444/talent-sauce/search",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },
    app: {
        one_profile: "https://resume-landing-test.web.app/",
        talent_search: "https://talent-search-dev.web.app/preview",
        talent_search_bookmark: "https://talent-search-dev.web.app/bookmarks",
        home: "http://localhost:4200/",
        why: "http://localhost:4200#whyhr9clinic",
        features: "http://localhost:4200#features",
        hr9_services: "https://d3e51han25shup.cloudfront.net",
        hr9_landing: "https://scoutout.co/hr9/detail?utm_source=HR9CLINIC"
    },
    
};
const ts_test = {
    base_url: "https://talent-sauce-test.web.app/",
    articles: {
        base_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator",
        recommend: "/recommend",
        view_url: "https://blog.talentsauce.co/"
    },
    indexFile: "./index.talentsauce.tsx",
    ga_4: "G-JYX6KBX3WK",
    ga: "",
    app_name: "TALENTSAUCE",
    origin_talent_search : "https://talent-search-dev.web.app",
    content_gen_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator/",
    authen: {
        sso_url : "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id : "TALENTSAUCE",
        redirect_uri : "https://talent-sauce-test.web.app/checking",
        redirect_uri_interview : "https://talent-sauce-test.web.app/",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://leeladev.scoutout.net",
            
        }
    },
  
   
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/talent-sauce",
        resume_checker: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
            base_url: "https://dprod-api.scoutout.net/resume-checker"
        },
        upload_resume: {
            type: "talent_sauce",
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        },
    },
   
    url:{
        scoutconnect_choose_company: "https://d3e51han25shup.cloudfront.net/home/choose-company",
    },
    crm:{
        is_show: false,
        base_url: "https://testlambda.scoutout.net:444/cms",
        sheet_id: "168Tzl95BtB0yU5mcjlz5xsZ1YpaOijqrMUD06EB_tew"
    },
    talentSeach: {
        base_url: "https://testlambda.scoutout.net:444/talent-sauce/search",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },
    app: {
        one_profile: "https://resume-landing-test.web.app/",
        talent_search: "https://talent-search-dev.web.app/preview",
        talent_search_bookmark: "https://talent-search-dev.web.app/bookmarks",
        home: "https://talent-sauce-test.web.app/",
        why: "https://talent-sauce-test.web.app/#whyhr9clinic",
        features: "https://talent-sauce-test.web.app/#features",
        hr9_services: "https://d3e51han25shup.cloudfront.net",
        hr9_landing: "https://scoutout.co/hr9/detail?utm_source=HR9CLINIC"
    }
};

const ts_prod = {
    base_url: "https://talentsauce.co/",
    articles: {
        base_url: "https://asia-southeast1-scoutout-mang-prod.cloudfunctions.net/contentgenerator",
        recommend: "/recommend",
        view_url: "https://blog.talentsauce.co/"
    },
    indexFile: "./index.ts.tsx",
    ga_4: "G-LY520FEYBB",
    ga: "",
    app_name: "TALENTSAUCE",
    origin_talent_search : "https://talent.scoutout.co",
    content_gen_url: "https://asia-southeast1-scoutout-mang-prod.cloudfunctions.net/contentgenerator/",
    authen: {
        sso_url : " https://account.scoutout.co",
        client_id : "TALENTSAUCE",
        redirect_uri : "https://talentsauce.co/checking",
        redirect_uri_interview : "https://talentsauce.co/interview-question",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://account-api.scoutoutapi.com",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://connect-api.scoutoutapi.com",
           
        }
    },
    
   
    chat_gpt: {
        base_url: "https://dprod-api.scoutout.net/talent-sauce",
        resume_checker: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
            base_url: "https://5slqj080tb.execute-api.ap-southeast-1.amazonaws.com/production/resume-checker"
        },
        upload_resume: {
            type: "talent_sauce",
            x_api_key: "0kz74WhgVda2JBHCGu7Qd3XSrhgRL5az146oon4w",
            base_url : "https://f3clcckfr7.execute-api.ap-southeast-1.amazonaws.com/production"
        },
    },
   
    url:{
        scoutconnect_choose_company: "https://connect.scoutout.co/home/choose-company",
    },
    crm:{
        is_show: false,
        base_url: "https://dprod-api.scoutout.net/production/cms",
        sheet_id: "168Tzl95BtB0yU5mcjlz5xsZ1YpaOijqrMUD06EB_tew"
    },
    talentSeach: {
        x_api_key: "1TUCk4v9nYaKrYQqoELs7aEaiXXbuis91jbjiEw1",
        base_url: "https://dprod-api.scoutout.net/talent-sauce/search",
    },
    app: {
        one_profile: "https://app.oneprofile.me/",
        talent_search: "https://talent.scoutout.co/preview",
        talent_search_bookmark: "https://talent.scoutout.co/bookmarks",
        home: "https://talentsauce.co/",
        why: "https://hr9-clinic.scoutout.co/#whyhr9clinic",
        features: "https://hr9-clinic.scoutout.co/#features",
        hr9_services: "https://hr9.scoutout.co",
        hr9_landing: "https://scoutout.co/hr9/detail?utm_source=HR9CLINIC"
    }
};

const config = 
    process.env.REACT_APP_ENVIRONMENT === 'production' ? prod : 
    process.env.REACT_APP_ENVIRONMENT === 'local' ? local :
    process.env.REACT_APP_ENVIRONMENT === 'talentsauce_test' ? ts_test:
    process.env.REACT_APP_ENVIRONMENT === 'talentsauce' ? ts_prod:
    process.env.REACT_APP_ENVIRONMENT === 'talentsauce_local' ? ts_local:
    process.env.REACT_APP_ENVIRONMENT === 'dev' ? dev
        : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};