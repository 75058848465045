import { Button, Form } from "react-bootstrap";
import languageConfig from "../../../language-config";
import "../../features/features.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import JDSuggestionModel from "../../../model/jd-suggestion.model";
import { useContext, useEffect, useState } from "react";
import { ProviderContext } from "../../../context/provider.context";
import ProfileSCModel from "../../../model/profile-sc.model";
import ConvertResultServices from "../../../services/convert-result.service";
import UtilityService from "../../../utils/utilities";
import ResultClinicComponent from "../result-clinic/result-clinic";
import { UplaodResumeModel } from "../../../model/upload-resume.model";
import EXAMPLE_JSON from "../../../assets/json/example/resume-prescreen.json";
import TRICK_JSON from "../../../assets/json/trick-feature.json";
import LoadingComponent from "../loading/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import templateConfig from "../../../template-config";
import GPTService from "../../../services/gpt.service";
import config from "../../../config";
import { useFilePicker } from "use-file-picker";
const ResumePrescreenComponent = ({ profileModel, submitWithOutCredit, authenError }: any) => {
    let inputRef: any;
    const { creditModel, setCreditModel }: any = useContext(ProviderContext)
    const [jdSuggestion, setJdSuggestion] = useState<JDSuggestionModel[]>([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [jobTitle, setJobTitle] = useState("");
    const [autocompleteList, setAutocompleteList] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(EXAMPLE_JSON["result"]);
    const [isValidate, setIsValidate] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const { setResultRawString }: any = useContext(ProviderContext)
    const [isShare, setIsShare] = useState(false);
    const [jobDescription, setJobDescription] = useState("");
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [fileUpload, setFileUpload] = useState<File>();
    const [isExample, setIsExample] = useState(true);
    const { openFilePicker, filesContent, loading, errors, clear } = useFilePicker({
        multiple: false,
        readAs: 'DataURL',
        accept: ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', '.doc', ".docx"],
        limitFilesConfig: { min: 0, max: 1 },
        maxFileSize: 20,
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {
            if (filesContent) {
                setFileUpload(filesContent[0]);
                setIsFileUpload(false);
            }
        
        },
        onFilesRejected: ({ errors }) => {
            // this callback is called when there were validation errors
        }
    });
    
    const resetALL = () => {
        setJobTitle("");
        setJobDescription("");
        setFileUpload(undefined);
        setResult("");
        setIsValidate(false);

    }

 
    const handleJobRoleChange = (event: any) => {
        setIsSubmiting(false);

        if (event) {
            try {
                if (event[0]["customOption"] != undefined) {
                    setJobTitle(event[0]["label"]);
                    console.log(event[0]["label"]);
                } else {
                    if (jdSuggestion) {
                        jdSuggestion.map((item: JDSuggestionModel, index: number) => {
                            if (item.job_title === event[0]) {
                                setJobTitle(item.job_title);
                                console.log(item.job_title);
                            }
                        })
                    }
                }
            } catch {

            }

        }
    }

    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = (file: any) => {
        const fileUploaded = file[0];

        if (fileUploaded) {
            setFileUpload(fileUploaded);
            const file = fileUploaded as File;
            setIsFileUpload(false);
        }
    };

    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel)
        }
    }, [profileModel])

    useEffect(() => {
        getJDSuggestion();
    }, [])

    const getJDSuggestion = () => {
        GPTService.getJDSuggestion().then((res: any) => {
            if(res){
                const resData = res.data as JDSuggestionModel[];
                setJdSuggestion(resData);
                const autoComp: string[] = [];
                resData.map((item: JDSuggestionModel, index: number) => {
                    autoComp.push(item.job_title);
                })
                setAutocompleteList(autoComp)
            }
           
        })
    }

    const onTextChange = (event: any, setTitle: any) => {
        const jt: string = event.target.value;
        setTitle(jt);
    }
    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }
    const submit = () => {
        setIsSubmiting(true);
        if (jobTitle && jobDescription && fileUpload && filesContent.length > 0) {
            setIsLoading(true);
            setResult("")
            setIsValidate(true);
            const formData = new FormData();
            console.log(fileUpload);
            const file = DataURIToBlob(filesContent[0]["content"]);
            formData.append("resume", file, filesContent[0].name);
            formData.append("type", config.chat_gpt.upload_resume.type);
            GPTService.uploadResume(formData).then((res) => {
                if (res ) {
                    if (res.data == "500") {
                        setResult("500")
                    }else if(res.status == 401){
                        authenError();
                    }else{
                        const resultKey = res.data as UplaodResumeModel;
                        GPTService.postResultResumeChecker(resultKey.resume_key, jobDescription, jobTitle, profileMod!.uuid).then((res2) => {
    
                            if (res2) {
                                if (res2.data == "500") {
                                    setResult("500")
                                }else{
                                    ConvertResultServices().ResumePreScreen(res2.data, jobTitle).then((resultTemp) => {
                                        UtilityService().clickSendEvent("resume_pre_screen_" + jobTitle, "click")
                                        setIsLoading(false);
                                        setResultRawString(resultTemp);
                                        setResult(resultTemp);
                                        setIsSubmiting(false);
                                        setIsShare(true);
                                        setIsExample(false);
                                        GPTService.getCredit().then((res3) => {
    
                                            if(res3){
                                                setCreditModel(res3.data)
                                            }
                                        })
                                    })
                                }
                                
                            } 
                        })
                    }
                    
                }
               
            }).catch((errs) => {
                console.log(errs);
                if(errs.response.status == 400){
                    alert("เกิดข้อผิดพลาดกับระบบ")
                }
            })

        }
    }
    const handleClick = (event: any) => {
        inputRef.click();
    }
  
    return (

        <>
            <div className="feature-control">


                <div className="feature-form-control">
                    <div className="feature-form-left-control">
                        <h1 className="feature-main-title">{languageConfig.content.resume_prescreen.title}</h1>
                        <span className="feature-main-desc">{languageConfig.content.resume_prescreen.description}</span>
                        <div className="feature-credit-usage-control">
                            <FontAwesomeIcon icon={faCoins} className="feature-credit-usage-icon"></FontAwesomeIcon>
                            <span className="feature-credit-usage-text">ใช้เครดิต:
                                <span className="feature-credit-usage-text-bold-line-through">{templateConfig.credit_usage.ai_tools.standard} เครดิต</span>
                                <span className="feature-credit-usage-text-bold">{templateConfig.credit_usage.ai_tools.discount} เครดิต</span></span>
                        </div>
                        {
                            isValidate ?

                                <>
                                    <div className="dashboard-form-item-submit-control">
                                        <div className="dashboard-form-item-border-control">
                                            <div className="dashboard-form-item-submit-item">
                                                <div className="dashboard-form-item-submit-title">
                                                    Job title (ตำแหน่งงาน)
                                                </div>
                                                <div className="dashboard-form-item-submit-desc">
                                                    {jobTitle}
                                                </div>
                                            </div>
                                            <div className="dashboard-form-item-submit-item">
                                                <div className="dashboard-form-item-submit-title">
                                                    Job Description (รายละเอียดงาน)
                                                </div>
                                                <div className="dashboard-form-item-submit-desc">
                                                    {jobDescription}
                                                </div>
                                            </div>
                                            <div className="dashboard-form-item-submit-item">
                                                <div className="dashboard-form-item-submit-title">
                                                    Upload Resume (อัปโหลดเรซูเม่)
                                                </div>
                                                <div className="dashboard-form-item-submit-desc">
                                                    {fileUpload!.name}
                                                </div>
                                            </div>
                                            <div className="dashboard-form-item-submit-item">
                                                <Button className="feature-submit-button" onClick={() => resetALL()} aria-expanded={isLoading} aria-controls="jd-generator">เริ่มใหม่อีกครั้ง</Button>

                                            </div>
                                        </div>

                                    </div>

                                </> :
                                <div className="dashboard-form-item-control">
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Job title (ตำแหน่งงาน)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Group>

                                            <Typeahead
                                                onChange={(e) => handleJobRoleChange(e)}
                                                id="valid-styles-example"
                                                inputProps={{
                                                    className: 'dashboard-input',
                                                }}
                                                options={autocompleteList}
                                            />
                                        </Form.Group>
                                        {
                                            (!jobTitle && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณากรอก Job title
                                            </Form.Text>
                                        }
                                    </div>
                                    <div className="dashboard-form-item">
                                        <Form.Label htmlFor="position" className="dashboard-form-item-title">Job Description (รายละเอียดงาน)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                        <Form.Control as="textarea" className="dashboard-input-textarea" cols={10} rows={5} onChange={(e) => onTextChange(e, setJobDescription)}></Form.Control>
                                        {
                                            (!jobDescription && isSubmiting) && <Form.Text className="dashboard-form-item-error" >
                                                กรุณากรอก Job Description
                                            </Form.Text>
                                        }
                                    </div>
                                    <div className="dashboard-form-item">

                                        <>
                                            <Form.Label htmlFor="position" className="dashboard-form-item-title">Upload Resume (อัปโหลดเรซูเม่)<span className="dashboard-form-item-title-required">*</span></Form.Label>

                                            {
                                                (fileUpload) ?

                                                    <button className="feature-button-resume" >{fileUpload!.name}</button>
                                                    :
                                                    <>
                                                        <Button className="feature-button-resume" onClick={openFilePicker}>อัปโหลดเรซูเม่</Button>

                                                      
                                                    </>
                                            }

                                        </>
                                    </div>
                                    {
                                        profileMod && <div className="feature-button-control" >
                                            {
                                                creditModel && creditModel.credit > 0 ?
                                                    <Button className="feature-submit-button" onClick={() => submit()} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.resume_prescreen.button}</Button>
                                                    : <Button className="feature-submit-button-gray" onClick={submitWithOutCredit} aria-expanded={isLoading} aria-controls="jd-generator">{languageConfig.content.resume_prescreen.button}</Button>
                                            }
                                        </div>
                                    }

                                </div>
                        }
                    </div>
                    <div className="">{
                           isLoading && !result ? <>
                           <LoadingComponent feature={TRICK_JSON[4].loading} listTrick={TRICK_JSON[4]} ></LoadingComponent> 
                    </> :
                        result && <ResultClinicComponent isExample={isExample} type={languageConfig.content.resume_prescreen.title} resultStr={result} isClose={false} isShare={isShare} isShareImg={false} setShowShare={setIsShare} ></ResultClinicComponent>
                    }

                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumePrescreenComponent;