import { useContext, useEffect, useState } from "react";
import "./history-candidate.scss";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ProfileSCModel from "../../../model/profile-sc.model";
import { StyledTableCell, StyledTableRow } from "../../../utils/theme";
import languageConfig from "../../../language-config";
import templateConfig from "../../../template-config";
import { CandidateHistoryModel } from "../../../model/talent-search/candidate-history.model";
import CandidateModal from "../../../modal/candidate.modal";
import { CandidateResultModel } from "../../../model/talent-search/candidate-result.model";
import UtilityService from "../../../utils/utilities";
import { ProviderContext } from "../../../context/provider.context";
import moment from "moment";
import TalentSearchService from "../../../services/talentsearch.service";




const HistoryCandidateComponent = () => {

    const [creditMod, setCreditMod] = useState<CandidateHistoryModel[]>();
    const { profileModel }: any = useContext(ProviderContext);
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const [isLoading, setIsLoading] = useState(false);
    const [isViewCandidate, setIsViewCandidate] = useState(false);
    const [viewCandidateID, setViewCandidateId] = useState<CandidateResultModel>();
    useEffect(() => {
        if (profileModel) {
            setProfileMod(profileModel);
            getCredit(profileModel);
            UtilityService().clickSendEvent("open_history_candidate_open", "load");
        }
    }, [profileModel])

    const getCredit = (profileModels) => {

        getHistoryTalentSearch(profileModels);

    }
    const getHistoryTalentSearch = (profileModels) => {
        setIsLoading(true);
        TalentSearchService.getHistory().then((res) => {
            setIsLoading(false);
            if (res) {
                setCreditMod(res.data);
            }

        })
    }

    const clickViewResponse = (candidateModel) => {
        setIsViewCandidate(true)
        setViewCandidateId(candidateModel)
    }

    const handleClose = () => {
        setIsViewCandidate(false);
    }


    const convertGMT = (dateString) => {
        // Create a Date object from the timestamp

        
        // Create a Date object from the timestamp
        const date = new Date(dateString);

        // Add 7 hours to the date (1 hour = 60 minutes = 3600 seconds = 3600000 milliseconds)
        const dateInGMT7 = new Date(date.getTime() + (7 * 3600000));

        const formatDateString = moment(dateInGMT7).format('DD/MM/YYYY HH:mm');
        return formatDateString;
    }


    return (

        <>

            <div className="history-candidate-main">

                <div className="history-candidate-main-control">
                    {
                        creditMod && creditMod.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table className="history-candidate-main-table-control" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="d-none d-md-table-cell" align="center" width='10%'>{languageConfig.history_talent_search.order}</StyledTableCell>
                                            <StyledTableCell className="d-none d-md-table-cell" align="center" width='20%'>{languageConfig.history_talent_search.id}</StyledTableCell>
                                            <StyledTableCell  className="d-table-cell d-md-none" align="left" width='50%'>{languageConfig.history_talent_search.name}</StyledTableCell>
                                            <StyledTableCell className="d-none d-md-table-cell" align="center" width='20%'>{languageConfig.history_talent_search.name}</StyledTableCell>
                                            <StyledTableCell className="d-none d-md-table-cell" align="center" width='10%'>{languageConfig.history_talent_search.exp_total}</StyledTableCell>
                                            <StyledTableCell className="d-none d-md-table-cell" align="center" width='20%'>{languageConfig.history_talent_search.created_date}</StyledTableCell>
                                            <StyledTableCell className="d-none d-md-table-cell" align="center" width='30%'>{languageConfig.history_talent_search.view_resume}</StyledTableCell>
                                            <StyledTableCell className="d-table-cell d-md-none" align="center" width='50%'>{languageConfig.history_talent_search.view_resume}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            creditMod.map((row: CandidateHistoryModel, index: number) => (
                                                <StyledTableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <StyledTableCell align="center" className="d-none d-md-table-cell" >
                                                        {index + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell className="d-none d-md-table-cell" align="center">{row.candidate_id}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.log_data.resume.first_name} {row.log_data.resume.last_name}</StyledTableCell>
                                                    <StyledTableCell className="d-none d-md-table-cell" align="center">{row.log_data.candidate_experiences.length > 0 ? row.log_data.candidate_experiences.length : "0"}</StyledTableCell>
                                                    <StyledTableCell className="d-none d-md-table-cell" align="center">{convertGMT(row.created_at)}</StyledTableCell>

                                                    <StyledTableCell align="center">
                                                        <Button className="history-candidate-button-view" onClick={() => clickViewResponse(row.log_data)}>{languageConfig.history.view_more}</Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))


                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer> :
                            isLoading ?

                                <div className="history-candidate-loading-control">
                                    <div className="history-candidate-loading-progress-control">
                                        <CircularProgress className="history-candidate-loading-progress"></CircularProgress>
                                        <span className="history-candidate-loading-text">กำลังโหลด</span>
                                    </div>
                                </div>
                                : <>

                                    <div className="history-candidate-no-result">

                                        <div className="history-candidate-no-result-control">
                                            <FontAwesomeIcon icon={faSearch} className="history-candidate-no-result-icon"></FontAwesomeIcon>
                                            <span className="history-candidate-no-result-text">{languageConfig.history.no_result}</span>
                                        </div>
                                    </div>
                                </>
                    }


                </div>
            </div>
            {
                isViewCandidate &&
                <CandidateModal open={isViewCandidate} handleClose={handleClose} id={viewCandidateID} profileModel={profileMod} isHistory={true} candidateModel={viewCandidateID}></CandidateModal>
            }

        </>
    )
}

export default HistoryCandidateComponent;