export const LOCATION_LIST = [{
    name: "Bangkok", value: "Bangkok", status: false
}, {
    name: "North", value: "North", status: false
},
{
    name: "Central", value: "Central", status: false
},
{
    name: "South", value: "South", status: false
},
{
    name: "Eastern", value: "Eastern", status: false
},
{
    name: "Northeast", value: "Northeast", status: false
},
{
    name: "Western", value: "Western", status: false
}]