import { Button, Container } from "react-bootstrap";
import HeaderLandingComponent from "./header-landing/header-landing";
import "./landing.scss";
import HR_CLINIC_IMG from "../../assets/images/hr-clinic-img.png";
import PROCESS_OF_HR_IMG from "../../assets/images/process-hr-img.png";
import JD_GENERATOR_IMG from "../../assets/images/jd-generator-hr-img.png";
import JD_GENERATOR_TEXT_IMG from "../../assets/images/jd-gen-text.png";
import JD_CHECKER_TEXT_IMG from "../../assets/images/jd-check-text.png";
import RESUME_PRE_TEXT_IMG from "../../assets/images/resume-pre-text.png";
import INTERVIEW_QUESTION_TEXT from "../../assets/images/interview-question-text.png";
import JOB_POST_TEXT_IMG from "../../assets/images/job-post-text.png";
import JD_CHECKER_IMG from "../../assets/images/jd-checker-hr-img.png";
import RESUME_PRE_SCREEN_IMG from "../../assets/images/resume-pre-screening-hr-img.png";
import INTERVIEW_GENERATOR_IMG from "../../assets/images/interview-questions-hr-img.png";
import JOB_POST_SOCIAL_IMG from "../../assets/images/job-post-social-hr-img.png";
import HR_CLINIC_WITH_BG_RIGHT from "../../assets/images/bg-text-right.png";
import HR_CLINIC_WITH_BG_LEFT from "../../assets/images/bg-text-right.png";
import FooterLandingComponent from "./footer-landing/footer-landing";
import { useRef, useEffect, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UtilityService from "../../utils/utilities";
import tokenService from "../../services/token.service";
import GAConfig from "../../ga-config";
import { getUTMParams, handleNavigation } from "../../services/handler-route.service";
import PopupModal from "../../modal/popup.modal";
const LandingComponent = () => {
    const location = useLocation();

    let refJDChecker = useRef<HTMLInputElement>(null);
    let refJobPost = useRef<HTMLInputElement>(null);
    let refResumeCheck = useRef<HTMLInputElement>(null);
    let refInterviewQuestion = useRef<HTMLInputElement>(null);
    let refWhy = useRef<HTMLInputElement>(null);
    let refFeatures = useRef<HTMLInputElement>(null);
    const [showPopupAds, setShowPopupAds] = useState(true);
    const navigate = useNavigate();
    const { utmSource, utmMedium, feature } = getUTMParams(location);
    const [isDashboard, setDashboard] = useState(false);
    const [isGuest, setIsGuest] = useState(false);
    const clickWhy = () => {

        if (refWhy) {
            UtilityService().clickSendEvent("click_why_landing", "click")
            refWhy.current!.scrollIntoView();
        }
    }

    const clickJdChecker = () => {

        if (refJDChecker) {
            UtilityService().clickSendEvent("click_jd_checker_landing", "click")
            refJDChecker.current!.scrollIntoView();
        }
    }

    const clickJobPost = () => {

        if (refJobPost) {
            UtilityService().clickSendEvent("click_job_post_landing", "click")
            refJobPost.current!.scrollIntoView();
        }
    }

    const clickResumeCheck = () => {

        if (refResumeCheck) {
            UtilityService().clickSendEvent("click_resume_check_landing", "click")
            refResumeCheck.current!.scrollIntoView();
        }
    }

    const clickInterviewQuestion = () => {

        if (refInterviewQuestion) {
            UtilityService().clickSendEvent("click_interview_question_generator_landing", "click")
            refInterviewQuestion.current!.scrollIntoView();
        }
    }
    const handleClosePopupAds = () => {
        setShowPopupAds(false);
    }
    const clickFeature = () => {

        if (refFeatures) {
            UtilityService().clickSendEvent("click_feauter_landing", "click")
            refFeatures.current!.scrollIntoView();
        }
    }





    useEffect(() => {
        if (feature == "jd_checker") {
            clickJdChecker();
        } else if (feature == "jd_generator") {
            clickFeature();
        } else if (feature == "interview_question") {
            clickInterviewQuestion();
        } else if (feature == "jobpost") {
            clickJobPost();
        } else if (feature == "resume_pre_screen") {
            clickResumeCheck();
        }
    }, [feature])
    useEffect(() => {
        if (window.location.href.includes("#whyhr9clinic")) {
            clickWhy();
        }
        if (window.location.href.includes("#features")) {
            clickFeature();
        }


    }, [window.location.href])



    const clickStart = () => {
        UtilityService().clickSendEvent("start_free_trial_landing", "click");
        tokenService.removeUser();
        localStorage.clear();
        handleNavigation(navigate, '/login', utmSource, utmMedium, feature, '');
    }


    const clickGotoDashboard = () => {
        UtilityService().clickSendEvent("go_to_dashboard_landing", "click")

        if (isGuest) {
            handleNavigation(navigate, '/checking', utmSource, utmMedium, feature, 'GUEST');
        } else {
            handleNavigation(navigate, '/checking', utmSource, utmMedium, feature, 'LOGIN');
        }

    }



    return (

        <>
            <Container className="landing-component">
                <HeaderLandingComponent ></HeaderLandingComponent>
                <div className="landing-component-cover-control">
                    <div className="landing-component-cover-text-control">
                        <span className="landing-component-cover-text-headline">
                            <img src={HR_CLINIC_WITH_BG_LEFT} className="landing-component-cover-text-headline-border"></img> <br className="d-md-none d-block"></br> เทคโนโลยี AI เพื่อช่วยให้การทำงาน HR ของคุณแตกต่างและไม่เหมือนใคร
                        </span>
                        <span className="landing-component-cover-text-desc">
                            ระบบที่ช่วยให้การจัดการทรัพยากรบุคคล สำหรับ HR ให้เป็นเรื่องง่าย
                            ด้วยคุณลักษณะและเครื่องมือที่มีความสำคัญ มาเริ่มต้นเพิ่มประสิทธิภาพ
                            โดยมีระบบ AI มาช่วยให้การบริหารจัดการทรัพยากรบุคคลแบบใหม่กันเถอะ!
                        </span>
                        <span className="landing-component-cover-why-text" onClick={clickWhy}>Why HR9 clinic?</span>
                        <div className="landing-component-cover-bottom-control">
                            {
                                isDashboard ? <Button className="button-default" onClick={clickGotoDashboard}>ไปหน้า Dashboard</Button> :
                                    <>
                                        <Button className="button-default landing-button-control" onClick={clickStart}>ทดลองใช้งานฟรี!</Button>
                                        {/* <span className="landing-component-cover-bottom-text" onClick={clickWhy}>หรือ</span> */}
                                        {/* <Button className="button-default landing-component-button-login" onClick={clickLogin}>เข้าสู่ระบบ / สมัครสมาชิก</Button> */}
                                    </>
                            }


                        </div>


                    </div>
                    <img src={HR_CLINIC_IMG} className="landing-component-cover-img"></img>
                </div>
                <div className="landing-component-section-2-control" ref={refWhy}>
                    <span className="landing-component-section-2-headline">ทำไมต้อง <img src={HR_CLINIC_WITH_BG_RIGHT} className="landing-component-section-2-headline-border"></img></span>
                    <img src={PROCESS_OF_HR_IMG} className="landing-component-section-2-img d-md-none d-block"></img>
                    <span className="landing-component-section-2-desc">ปัญหาที่ HR พบเจอในการโพสต์งานคือการสร้างโพสต์งานที่น่าสนใจเพื่อดึงดูดผู้สมัครที่มีคุณสมบัติและสอดคล้องกับตำแหน่งงานและองค์กรอย่างถูกต้อง อีกปัญหาคือการกรองผู้สมัครและประเมินเรซูเม่อย่างมีประสิทธิภาพเพื่อคัดเลือกผู้สมัครที่เหมาะสม ซึ่งอาจใช้เวลานานและทรัพยากรมากเกินไป นอกจากนี้การประเมินเรซูเม่และคุณสมบัติของผู้สมัครมีความเป็นส่วนบุคคลและมีโอกาสเกิดความลำเอียง ซึ่ง HR จำเป็นต้องพัฒนากลยุทธ์ที่มีประสิทธิภาพและใช้เครื่องมือเทคโนโลยีเพื่อปรับปรุงกระบวนการเหล่านี้ ให้เป็นมาตรฐาน และค้นหาผู้สมัครที่เหมาะสมที่สุดสำหรับงานนั้นๆ</span>
                    <img src={PROCESS_OF_HR_IMG} className="landing-component-section-2-img  d-md-block d-none"></img>
                </div>
                <div className="landing-component-section-3-control" ref={refFeatures}>
                    <div className="landing-component-section-3-text-control  order-1 order-md-0">
                        <img src={JD_GENERATOR_TEXT_IMG} className="landing-component-section-3-headline-img"></img>
                        <span className="landing-component-section-3-desc">  HR9 Clinic มาพร้อมกับคุณสมบัติ JD Generator ที่จะช่วยให้คุณสร้างรายละเอียดงานได้อย่างง่ายดาย
                            ไม่ต้องลังเลหรือสับสนอีกต่อไป เพียงแค่ใส่ข้อมูลเบื้องต้นเกี่ยวกับงานที่ต้องการ โปรแกรมจะทำการสร้าง
                            JD (Job Description) ที่มีความครอบคลุมและถูกต้องตามแนวทางที่ดีที่สุด
                            ดังนั้นคุณสามารถประหยัดเวลาและความพยายามในการเขียนรายละเอียดงานได้อย่างมีประสิทธิภาพ</span>

                    </div>
                    <img src={JD_GENERATOR_IMG} className="landing-component-section-3-img-right order-0 order-md-1"></img>
                </div>
                <div className="landing-component-section-3-control" ref={refJDChecker}>
                    <img src={JD_CHECKER_IMG} className="landing-component-section-3-img-left"></img>
                    <div className="landing-component-section-3-text-control">
                        <img src={JD_CHECKER_TEXT_IMG} className="landing-component-section-3-headline-img"></img>
                        <span className="landing-component-section-3-desc">  เพื่อให้แน่ใจว่ารายละเอียดงานที่คุณเขียนเป็นไปตามมาตรฐานและไม่มีข้อผิดพลาด
                            โปรแกรม JD Checker ของ HR9 Clinic
                            จะทำการตรวจสอบความสอดคล้องระหว่างรายละเอียดงานที่คุณเขียนกับแบบฟอร์มที่กำหนด
                            และจะแสดงข้อผิดพลาดที่พบ (หากมี) เพื่อให้คุณแก้ไขและปรับปรุงก่อนที่จะเผยแพร่รายละเอียดงาน</span>

                    </div>

                </div>
                <div className="landing-component-section-3-control" ref={refJobPost}>
                    <div className="landing-component-section-3-text-control order-1 order-md-0">
                        <img src={JOB_POST_TEXT_IMG} className="landing-component-section-3-headline-img"></img>
                        <span className="landing-component-section-3-desc">   เพิ่มโอกาสในการรับสมัครงานโดยการโพสต์งานลงบนโซเชียลมีเดียที่ได้รับความนิยม ด้วยคุณสมบัติ Job Post to Social Media ของ HR9 Clinic คุณสามารถทำการโพสต์งานบนแพลตฟอร์มโซเชียลมีเดียต่างๆ
                            อาทิเช่น เฟซบุ๊ค ทวิตเตอร์ หรือลิงค์ลงในลิงค์ไบโอของอินสตาแกรม
                            เพื่อให้ผู้สมัครที่ใช้โซเชียลมีเดียต่างๆ เข้าถึงข้อมูลงานที่คุณโพสต์ได้อย่างง่ายดาย</span>

                    </div>
                    <img src={JOB_POST_SOCIAL_IMG} className="landing-component-section-3-img-right order-0 order-md-1"></img>
                </div>
                <div className="landing-component-section-3-control" ref={refResumeCheck}>
                    <img src={RESUME_PRE_SCREEN_IMG} className="landing-component-section-3-img-left"></img>
                    <div className="landing-component-section-3-text-control">
                        <img src={RESUME_PRE_TEXT_IMG} className="landing-component-section-3-headline-img"></img>
                        <span className="landing-component-section-3-desc">  HR9 Clinic มีระบบคัดกรองเรซูเม่ที่ทันสมัยและมีประสิทธิภาพ เมื่อคุณรับเรซูเม่จากผู้สมัครงาน
                            ระบบจะทำการวิเคราะห์และตรวจสอบเรซูเม่ในขั้นตอนแรกเพื่อตัดสินใจว่าควรนำเรซูเม่นั้นไปต่อในกระบวนการคัดเลือกหรือไม่
                            ระบบจะใช้เทคนิคและข้อกำหนดที่คุณกำหนดเองเพื่อเลือกเรซูเม่ที่เหมาะสมที่สุด</span>

                    </div>
                </div>
                <div className="landing-component-section-3-control" ref={refInterviewQuestion}>
                    <div className="landing-component-section-3-text-control order-1 order-md-0">
                        <img src={INTERVIEW_QUESTION_TEXT} className="landing-component-section-3-headline-img"></img>
                        <span className="landing-component-section-3-desc">สร้างคำถามสัมภาษณ์ที่น่าสนใจและเหมาะสมไม่ใช่เรื่องยากอีกต่อไป
                            โปรแกรม Interview Questions Generator ของ HR9 Clinic
                            จะช่วยให้คุณสร้างคำถามสัมภาษณ์ที่เป็นไปตามความต้องการของงานและสามารถทดสอบความรู้และทักษะของผู้สมัครได้อย่างมีประสิทธิภาพ
                            และทำให้กระบวนการสัมภาษณ์เป็นไปอย่างราบรื่น</span>

                    </div>
                    <img src={INTERVIEW_GENERATOR_IMG} className="landing-component-section-3-img-right order-0 order-md-1"></img>
                </div>
            </Container>
            {
                process.env.REACT_APP_TECHSAUCE ?

                    <FooterLandingComponent></FooterLandingComponent> : <FooterLandingComponent></FooterLandingComponent>


            }

            {
                showPopupAds && <PopupModal showMod={showPopupAds} handleClose={handleClosePopupAds} />
            }

        </>
    )
}

export default LandingComponent;