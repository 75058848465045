import { Button, Modal } from "react-bootstrap";
import "./campaign-detail.scss";
import CRMServices from "../../../../../services/crm.service";
import { useEffect, useState } from "react";
import { CircularProgress, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import { CampaignDetailModel, Click, Variant } from "../../../../../model/campaign/campaign-detail.model";
import { Audience } from "../../../../../model/audience/audience-list.model";
import templateConfig from "../../../../../template-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const CampaignDetailComponent = ({ isOpen, handleClose, id }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [detailCampaign, setDetailCampaign] = useState<CampaignDetailModel>();
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--primary-color)",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    useEffect(() => {
        getCampaignDetail();
    }, [])

    const getCampaignDetail = () => {

        if (id) {
            setIsLoading(true);
            CRMServices().getCampaignDetailById(id).then((res) => {

                if (res) {
                    const result = res as CampaignDetailModel;
                    console.log(result);
                    result.variants.map((item: Variant, index: number) => {
                        result.variants[index].status_show = false;
                    })
                    setDetailCampaign(result);
                    setIsLoading(false);
                }
            })
        }
    }

    const showDetailInsight = (index) => {

        if (detailCampaign) {
            const detailCampMod = { ...detailCampaign };
            if(detailCampMod.variants[index].status_show){
                detailCampMod.variants[index].status_show = false
            }else{
                detailCampMod.variants[index].status_show = true
            }
            setDetailCampaign(detailCampMod);
        }
    }


    return (

        <>
            <div className="campaign-detail-modal">
                <Modal show={isOpen} onHide={handleClose} className="campaign-detail-modal" backdrop={'static'} >
                    <Modal.Header closeButton>
                        <Modal.Title>รายละเอียด Campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {
                            detailCampaign &&

                            <div className="campaign-detail-control">
                                <span className="campaign-detail-title">Campaign name: {detailCampaign.broadcast_name}</span>
                                <span className="campaign-detail-title">Target Volume: {detailCampaign.audience_approx_count.toLocaleString()} users</span>
                                <span className="campaign-detail-title">Created at: {new Date(detailCampaign.created_at).toLocaleString()}</span>

                                <div className="campaign-detail-border-control">
                                    <span className="campaign-detail-title">กลุ่ม Audience ที่เลือก</span>
                                    <div className="campaign-detail-tag-control" >
                                        {
                                            detailCampaign.audiences.map((item: Audience, index: number) => {

                                                return (

                                                    <div key={index} className="campaign-detail-tag-value">{item.audience_name}</div>

                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="campaign-detail-border-control">
                                    <span className="campaign-detail-title">Insight {
                                        detailCampaign.variants.length == 1 ? "" : "(A/B Testing)"
                                    }</span>
                                    <div className="campaign-detail-table-grid-control">
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="left" width='30%'>Variant Name</StyledTableCell>
                                                        <StyledTableCell align="center" width='20%'>Delivered</StyledTableCell>
                                                        <StyledTableCell align="center" width='10%'>Unique Click</StyledTableCell>
                                                        <StyledTableCell align="center" width='10%'>Unique Impression</StyledTableCell>
                                                        <StyledTableCell align="center" width='30%'></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        detailCampaign.variants.map((item: Variant, index: number) => {

                                                            return (
                                                                <>
                                                                    <StyledTableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <StyledTableCell align="left">
                                                                            {item.name}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">{item.insight.overview.delivered.toLocaleString()}</StyledTableCell>
                                                                        <StyledTableCell align="center">{item.insight.overview.unique_click.toLocaleString()}</StyledTableCell>
                                                                        <StyledTableCell align="center">{item.insight.overview.unique_impression.toLocaleString()}</StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            <div className="campaign-detail-link-more-control" onClick={() => showDetailInsight(index)}>
                                                                                <span className="campaign-detail-link-more">ดูรายละเอียด</span>
                                                                                {
                                                                                    item.status_show ?
                                                                                        <FontAwesomeIcon className="campaign-detail-link-more-icon" icon={faChevronUp}></FontAwesomeIcon> :
                                                                                        <FontAwesomeIcon className="campaign-detail-link-more-icon" icon={faChevronDown}></FontAwesomeIcon>

                                                                                }

                                                                            </div>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                    {
                                                                        item.status_show &&
                                                                        item.insight.clicks.map((items: Click, indexs: number) => {

                                                                            return (
                                                                                <>
                                                                                    <StyledTableRow
                                                                                        key={indexs}
                                                                                        style={{ backgroundColor: '#efefef'}}
                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                    >
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" colSpan={5}>
                                                                                            Job card: {items.jobnow?.job.job_title}
                                                                                        </StyledTableCell>

                                                                                    </StyledTableRow>

                                                                                    <StyledTableRow
                                                                                        key={indexs}
                                                                                        style={{ backgroundColor: '#efefef' }}
                                                                                    >
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" >
                                                                                            <span className="campaign-more-detail-desc">Click</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="left" className="campaign-more-detail-desc-control" colSpan={4}>
                                                                                            <span className="campaign-more-detail-desc"> {items.click}</span>
                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                    <StyledTableRow
                                                                                        key={indexs}
                                                                                        style={{ backgroundColor: '#efefef' }}
                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                    >
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" colSpan={3} >
                                                                                            Interest/Lifestyle
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" colSpan={2} >
                                                                                            Demographic
                                                                                        </StyledTableCell>

                                                                                    </StyledTableRow>
                                                                                    <StyledTableRow
                                                                                        key={indexs}
                                                                                        style={{ backgroundColor: '#efefef', border: 'none' }}
                                                                                    >
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" >
                                                                                            <span className="campaign-more-detail-desc">Job Applicants</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="left" colSpan={2} className="campaign-more-detail-desc-control">
                                                                                            <span className="campaign-more-detail-desc"> {items.jobnow?.job.job_applications}</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" >
                                                                                            <span className="campaign-more-detail-desc">Age</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="left" colSpan={2} className="campaign-more-detail-desc-control">
                                                                                            <span className="campaign-more-detail-desc"> {items.jobnow?.top_audience.age} ปี</span>
                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                    <StyledTableRow
                                                                                        key={indexs}
                                                                                        style={{ backgroundColor: '#efefef', border: 'none' }}
                                                                                    >
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" >
                                                                                            <span className="campaign-more-detail-desc">Job Role</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="left" colSpan={2} className="campaign-more-detail-desc-control">
                                                                                            <span className="campaign-more-detail-desc"> {items.jobnow?.job.job_role}</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" >
                                                                                            <span className="campaign-more-detail-desc">Gender</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="left" colSpan={2} className="campaign-more-detail-desc-control">
                                                                                            <span className="campaign-more-detail-desc"> {items.jobnow?.top_audience.gender}</span>
                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                    <StyledTableRow
                                                                                        key={indexs}
                                                                                        style={{ backgroundColor: '#efefef', border: 'none' }}
                                                                                    >
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" >
                                                                                            <span className="campaign-more-detail-desc">Job Title</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="left" colSpan={2} className="campaign-more-detail-desc-control">
                                                                                            <span className="campaign-more-detail-desc"> {items.jobnow?.job.job_title}</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell className="campaign-more-detail-title" colSpan={3} align="left" >
                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                    <StyledTableRow
                                                                                        key={indexs}
                                                                                        style={{ backgroundColor: '#efefef', border: 'none' }}
                                                                                    >
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" >
                                                                                            <span className="campaign-more-detail-desc">Education</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="left" colSpan={2} className="campaign-more-detail-desc-control">
                                                                                            <span className="campaign-more-detail-desc"> {items.jobnow?.top_audience.education}</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell className="campaign-more-detail-title" colSpan={3} align="left" >
                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                    <StyledTableRow
                                                                                        key={indexs}
                                                                                        style={{ backgroundColor: '#efefef', border: 'none' }}
                                                                                    >
                                                                                        <StyledTableCell className="campaign-more-detail-title" align="left" >
                                                                                            <span className="campaign-more-detail-desc">Experience</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="left" colSpan={2} className="campaign-more-detail-desc-control">
                                                                                            <span className="campaign-more-detail-desc"> {items.jobnow?.top_audience.experiences.years} ปี</span>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell className="campaign-more-detail-title" colSpan={3} align="left" >
                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                    <StyledTableRow
                                                                                        key={indexs}
                                                                                        style={{ backgroundColor: '#efefef', border: 'none' }}
                                                                                    >

                                                                                        <StyledTableCell colSpan={5} className="campaign-more-detail-divider"></StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                </>
                                                                            )
                                                                        })

                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </TableBody>

                                            </Table>
                                        </TableContainer >
                                    </div>
                                </div >



                            </div>
                        }

                        {
                            isLoading &&

                            <>
                                <div className="campaign-detail-loading-control">
                                    <div className="campaign-detail-loading-progress-control">
                                        <CircularProgress className="campaign-detail-loading-progress"></CircularProgress>
                                        <span className="campaign-detail-loading-text">กำลังโหลด</span>
                                    </div>
                                </div>
                            </>
                        }

                    </Modal.Body>

                </Modal>
            </div >
        </>
    )
}

export default CampaignDetailComponent;