


export function buildURLWithUTMParams(url, utmSource, utmMedium, feature,version?, redirect?) {
    const searchParams = new URLSearchParams();
    if (utmSource) {
        searchParams.set('utm_source', utmSource);
    }
    if (utmMedium) {
        searchParams.set('utm_medium', utmMedium);
    }

    if(version){
        searchParams.set('version', version);
    }
    if(feature){
        searchParams.set('feature', feature);
    }

    if(redirect){
        searchParams.set('redirect', redirect);
    }

    const queryString = searchParams.toString();
    return queryString ? `${url}?${queryString}` : url;
}
export const handleNavigation = (navigate, page, utmSource, utmMedium, feature, version?, redirect?) => {

    if(redirect){
        console.log(redirect);
        const newURL = buildURLWithUTMParams(page, utmSource, utmMedium, feature, version, redirect);
        navigate(newURL);
    }else{
        const newURL = buildURLWithUTMParams(page, utmSource, utmMedium, feature, version);
        navigate(newURL);
    }
  
  };

export function getUTMParams(location) {
    const searchParams = new URLSearchParams(location.search);
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const version = searchParams.get('version');
    const redirect = searchParams.get('redirect');
    const feature = searchParams.get('feature');
    return { utmSource, utmMedium, feature, version, redirect };
}



