

class LanugageService {
   
    getLanguage() {
      return localStorage.getItem("language");
    }
  
    setLang(lang) {
      localStorage.setItem("language", lang);
    }
   
  }
  
  export default new LanugageService();