import { Tab, Tabs } from "@mui/material";
import "./history.scss";
import { useEffect, useState } from "react";
import HistoryCreditComponent from "./history-credit";
import HistoryCandidateComponent from "./history-candidate/history-candidate";
import ProfileSCModel from "../../model/profile-sc.model";
import templateConfig from "../../template-config";
import UtilityService from "../../utils/utilities";

const HistoryComponent = ({ profileModel }: any) => {
    const [value, setValue] = useState('one');
    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (profileMod) {
            UtilityService().clickSendEvent("open_credit_history", "load");
        }
    }, [profileMod])

    return (

        <>
            {
                profileMod &&
                <div className="history-main">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        TabIndicatorProps={{style: {
                            backgroundColor: "var(--primary-color)"
                         }}}
                         className="history-main-tab-custom"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="one" label="AI Tools" />
                        <Tab value="two" label="Talent Search" />
                    </Tabs>
                    {
                        value == "one" ?
                            <HistoryCreditComponent profileModel={profileMod}></HistoryCreditComponent> :
                            <HistoryCandidateComponent ></HistoryCandidateComponent>

                    }
                </div>
            }

        </>
    )
}

export default HistoryComponent;