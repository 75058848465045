
const en = {

    loading: "กำลังโหลดข้อมูล...",

    login_page:{
        title: "เพิ่มประสิทธิภาพในการสรรหาบุคลากร",
        register_button: "ลงทะเบียน",
        login_button: "เข้าสู่ระบบ",
        footer_text: "©ScoutOut recruitment limited"
    },

    candidate_profile: {
        history_exp: "ประวัติการทำงาน",
        history_education: "ประวัติการศึกษา"
    },

    history:{
        order: "ลำดับ",
        features: "ฟีเจอร์ที่ใช้งาน",
        created_date: "วันที่ใช้งาน",
        credit_used: "เครดิตที่ใช้",
        view_more: "ดูผลลัพธ์",
        no_result: "ยังไม่มีประวัติการใช้งาน"
    },

    recommend_modal:{
        title: "เรามี เรซูเม่ มาแนะนำ",
        experience: "ประสบการณ์ล่าสุด",
        skills: "ทักษะที่มี",
        last_exp: "ตำแหน่งงานล่าสุด",
        click_open: "(คลิกเพื่อเปิด)",
        view_info: "ดูข้อมูล",
        cancel: "ยกเลิก",
        pay_credit: "ตกลง 50 เครดิต",
        confirm_credit: "ใช้ 50 เครดิตเพื่อเปิดดูข้อมูล",
        salary: "เงินเดือน"

    },

    history_talent_search: {
        order: "ลำดับ",
        id: "รหัส Candidate",
        name: "ชื่อ-นามสกุล",
        view_resume: "ดู Resume",
        created_date: "เปิดดูวันที่",
        exp_total: "จำนวนประสบการณ์"
    },

    error: {
        input_error: "กรุณากรอก",
        select_error: "กรุณาเลือก",
        email_validate: "อีเมล ไม่ถูกต้อง"
    },

    register_page:{
        title: "ลงทะเบียนเข้าใช้งาน",
        company_name: "ชื่อบริษัท*",
        industry: "ประเภทบริษัท*",
        company_size: "ขนาดบริษัท (จำนวนพนักงาน)*",
        email_company: "อีเมลบริษัท (สำหรับติดต่อ)*",
        submit: "ลงทะเบียน"
    },

    main_menu: {
        is_pro: "PRO",
        group_find_talent: "Talent Acquisition (สรรหาผู้สมัคร)",
        group_manage_talent: "จัดการผู้สมัคร",
        group_others: "Company Branding",
        home: "หน้าหลัก",
        profile: "โปรไฟล์",
        history: "ประวัติการใช้งาน",
        jd_generator: "สร้าง JD",
        jd_checker: "ตรวจสอบ JD",
        jobpost_generator: "สร้างโพสต์งานบน Social",
        resume_screen: "ตรวจสอบ Resume",
        interview_question:"สร้างคำถามสัมภาษณ์",
        salary_offering: "เช็คเงินเดือนสำหรับ Offer",
        talent_search: "Talent Search <br>(ค้นหาผู้สมัคร)",
        line_oa_crm: "จัดการแคมเปญ บน LINE OA",
        post_job_ats: "โพสต์งานผ่าน <br>Job Boards ชั้นนำ",
        manage_candidate_ats: "ระบบติดตามผู้สมัคร <br>(ATS)",
        create_career_site: "สร้าง Career Site ขององค์กร"
    },

    header:{
        credits: "เครดิต",
        no_credits: "ไม่มีเครดิต"
    },

    content:{
        jd_generator:{
            title: "สร้าง JD (Job Description Generator)",
            description: "มาพร้อมกับคุณสมบัติ JD Generator ที่จะช่วยให้คุณสร้างรายละเอียดงานได้อย่างง่ายดาย ไม่ต้องลังเลหรือสับสนอีกต่อไป เพียงแค่ใส่ข้อมูลเบื้องต้นเกี่ยวกับงานที่ต้องการ โปรแกรมจะทำการสร้าง JD (Job Description) ที่มีความครอบคลุมและถูกต้องตามแนวทางที่ดีที่สุด ดังนั้นคุณสามารถประหยัดเวลาและความพยายามในการเขียนรายละเอียดงานได้อย่างมีประสิทธิภาพ",
            button: "เริ่มสร้าง JD"
        },
        jd_checker: {
            title: "ตรวจสอบ JD (Job Description Checker)",
            description: "เพื่อให้แน่ใจว่ารายละเอียดงานที่คุณเขียนเป็นไปตามมาตรฐานและไม่มีข้อผิดพลาด โปรแกรม JD Checker จะทำการตรวจสอบความสอดคล้องระหว่างรายละเอียดงานที่คุณเขียนกับแบบฟอร์มที่กำหนด และจะแสดงข้อผิดพลาดที่พบ (หากมี) เพื่อให้คุณแก้ไขและปรับปรุงก่อนที่จะเผยแพร่รายละเอียดงาน",
            button: "เริ่มตรวจสอบ JD"
        },
        resume_prescreen: {
            title: "ตรวจสอบ เรซูเม่ผู้สมัคร (Resume Pre Screening)",
            description: "มีระบบคัดกรองเรซูเม่ที่ทันสมัยและมีประสิทธิภาพ เมื่อคุณรับเรซูเม่จากผู้สมัครงาน ระบบจะทำการวิเคราะห์และตรวจสอบเรซูเม่ในขั้นตอนแรกเพื่อตัดสินใจว่าควรนำเรซูเม่นั้นไปต่อในกระบวนการคัดเลือกหรือไม่ ระบบจะใช้เทคนิคและข้อกำหนดที่คุณกำหนดเองเพื่อเลือกเรซูเม่ที่เหมาะสมที่สุด",
            button: "เริ่มตรวจสอบ Resume"
        },
        salary_offer: {
            title: "คำนวนเงินเดือน (Salary Offering)",
            description: "ผู้ใช้งานสามารถใช้ระบบ Salary Offering เพื่อกำหนดเงินเดือนหรือการเสนอเงินเดือนให้สอดคล้องกับตำแหน่งงานและความต้องการของบุคคลในองค์กร ระบบช่วยให้มีการเสนอเงินเดือนที่ยุติธรรมและเพิ่มความพึงพอใจของพนักงาน และสามารถให้ข้อมูลและการประเมินเกี่ยวกับข้อเสนอเงินเดือนให้กับผู้บริหารหรือผู้ตัดสินใจสามารถตัดสินใจเกี่ยวกับเงินเดือนได้อย่างเป็นระบบและถูกต้องมากขึ้น",
            button: "เริ่มคำนวนเงินเดือน"
        },
        post_job_social: {
            title: "สร้างโพสต์งานไปยัง Social Media (Job Post Social)",
            description: "เพิ่มโอกาสในการรับสมัครงานโดยการโพสต์งานลงบนโซเชียลมีเดียที่ได้รับความนิยม ด้วยคุณสมบัติ Job Post to Social Media ของ คุณสามารถทำการโพสต์งานบนแพลตฟอร์มโซเชียลมีเดียต่างๆ อาทิเช่น เฟซบุ๊ค ทวิตเตอร์ หรือลิงค์ลงในลิงค์ไบโอของอินสตาแกรม เพื่อให้ผู้สมัครที่ใช้โซเชียลมีเดียต่างๆ เข้าถึงข้อมูลงานที่คุณโพสต์ได้อย่างง่ายดาย",
            button: "เริ่มสร้างโพสต์งาน"
        },
        interview_question: {
            title: "สร้างคำถามในการสัมภาษณ์ (Interview Question Generator)",
            description: "สร้างคำถามสัมภาษณ์ที่น่าสนใจและเหมาะสมไม่ใช่เรื่องยากอีกต่อไป โปรแกรม Interview Questions Generator ของ จะช่วยให้คุณสร้างคำถามสัมภาษณ์ที่เป็นไปตามความต้องการของงานและสามารถทดสอบความรู้และทักษะของผู้สมัครได้อย่างมีประสิทธิภาพ และทำให้กระบวนการสัมภาษณ์เป็นไปอย่างราบรื่น",
            button: "เริ่มสร้างคำถามสัมภาษณ์งาน"
        },
    }
}
const th = {

    loading: "กำลังโหลดข้อมูล...",

    login_page:{
        title: "เพิ่มประสิทธิภาพในการสรรหาบุคลากร",
        register_button: "ลงทะเบียน",
        login_button: "เข้าสู่ระบบ",
        footer_text: "©ScoutOut recruitment limited"
    },

    candidate_profile: {
        history_exp: "ประวัติการทำงาน",
        history_education: "ประวัติการศึกษา"
    },

    history:{
        order: "ลำดับ",
        features: "ฟีเจอร์ที่ใช้งาน",
        created_date: "วันที่ใช้งาน",
        credit_used: "เครดิตที่ใช้",
        view_more: "ดูผลลัพธ์",
        no_result: "ยังไม่มีประวัติการใช้งาน"
    },

    recommend_modal:{
        title: "เรามี เรซูเม่ มาแนะนำ",
        experience: "ประสบการณ์ล่าสุด",
        skills: "ทักษะที่มี",
        last_exp: "ตำแหน่งงานล่าสุด",
        click_open: "(คลิกเพื่อเปิด)",
        view_info: "ดูข้อมูล",
        cancel: "ยกเลิก",
        pay_credit: "ตกลง 50 เครดิต",
        confirm_credit: "ใช้ 50 เครดิตเพื่อเปิดดูข้อมูล",
        salary: "เงินเดือน"

    },

    history_talent_search: {
        order: "ลำดับ",
        id: "รหัส Candidate",
        name: "ชื่อ-นามสกุล",
        view_resume: "ดู Resume",
        created_date: "เปิดดูวันที่",
        exp_total: "จำนวนประสบการณ์"
    },

    error: {
        input_error: "กรุณากรอก",
        select_error: "กรุณาเลือก",
        email_validate: "อีเมล ไม่ถูกต้อง"
    },

    register_page:{
        title: "ลงทะเบียนเข้าใช้งาน",
        company_name: "ชื่อบริษัท*",
        industry: "ประเภทบริษัท*",
        company_size: "ขนาดบริษัท (จำนวนพนักงาน)*",
        email_company: "อีเมลบริษัท (สำหรับติดต่อ)*",
        submit: "ลงทะเบียน"
    },

    main_menu: {
        is_pro: "PRO",
        group_find_talent: "Talent Acquisition (สรรหาผู้สมัคร)",
        group_manage_talent: "จัดการผู้สมัคร",
        group_others: "Company Branding",
        home: "หน้าหลัก",
        profile: "โปรไฟล์",
        history: "ประวัติการใช้งาน",
        jd_generator: "สร้าง JD",
        jd_checker: "ตรวจสอบ JD",
        jobpost_generator: "สร้างโพสต์งานบน Social",
        resume_screen: "ตรวจสอบ Resume",
        interview_question:"สร้างคำถามสัมภาษณ์",
        salary_offering: "เช็คเงินเดือนสำหรับ Offer",
        talent_search: "Talent Search <br>(ค้นหาผู้สมัคร)",
        line_oa_crm: "จัดการแคมเปญ บน LINE OA",
        post_job_ats: "โพสต์งานผ่าน <br>Job Boards ชั้นนำ",
        manage_candidate_ats: "ระบบติดตามผู้สมัคร <br>(ATS)",
        create_career_site: "สร้าง Career Site ขององค์กร"
    },

    header:{
        credits: "เครดิต",
        no_credits: "ไม่มีเครดิต"
    },

    content:{
        jd_generator:{
            title: "สร้าง JD (Job Description Generator)",
            description: "มาพร้อมกับคุณสมบัติ JD Generator ที่จะช่วยให้คุณสร้างรายละเอียดงานได้อย่างง่ายดาย ไม่ต้องลังเลหรือสับสนอีกต่อไป เพียงแค่ใส่ข้อมูลเบื้องต้นเกี่ยวกับงานที่ต้องการ โปรแกรมจะทำการสร้าง JD (Job Description) ที่มีความครอบคลุมและถูกต้องตามแนวทางที่ดีที่สุด ดังนั้นคุณสามารถประหยัดเวลาและความพยายามในการเขียนรายละเอียดงานได้อย่างมีประสิทธิภาพ",
            button: "เริ่มสร้าง JD"
        },
        jd_checker: {
            title: "ตรวจสอบ JD (Job Description Checker)",
            description: "เพื่อให้แน่ใจว่ารายละเอียดงานที่คุณเขียนเป็นไปตามมาตรฐานและไม่มีข้อผิดพลาด โปรแกรม JD Checker จะทำการตรวจสอบความสอดคล้องระหว่างรายละเอียดงานที่คุณเขียนกับแบบฟอร์มที่กำหนด และจะแสดงข้อผิดพลาดที่พบ (หากมี) เพื่อให้คุณแก้ไขและปรับปรุงก่อนที่จะเผยแพร่รายละเอียดงาน",
            button: "เริ่มตรวจสอบ JD"
        },
        resume_prescreen: {
            title: "ตรวจสอบ เรซูเม่ผู้สมัคร (Resume Pre Screening)",
            description: "มีระบบคัดกรองเรซูเม่ที่ทันสมัยและมีประสิทธิภาพ เมื่อคุณรับเรซูเม่จากผู้สมัครงาน ระบบจะทำการวิเคราะห์และตรวจสอบเรซูเม่ในขั้นตอนแรกเพื่อตัดสินใจว่าควรนำเรซูเม่นั้นไปต่อในกระบวนการคัดเลือกหรือไม่ ระบบจะใช้เทคนิคและข้อกำหนดที่คุณกำหนดเองเพื่อเลือกเรซูเม่ที่เหมาะสมที่สุด",
            button: "เริ่มตรวจสอบ Resume"
        },
        salary_offer: {
            title: "คำนวนเงินเดือน (Salary Offering)",
            description: "ผู้ใช้งานสามารถใช้ระบบ Salary Offering เพื่อกำหนดเงินเดือนหรือการเสนอเงินเดือนให้สอดคล้องกับตำแหน่งงานและความต้องการของบุคคลในองค์กร ระบบช่วยให้มีการเสนอเงินเดือนที่ยุติธรรมและเพิ่มความพึงพอใจของพนักงาน และสามารถให้ข้อมูลและการประเมินเกี่ยวกับข้อเสนอเงินเดือนให้กับผู้บริหารหรือผู้ตัดสินใจสามารถตัดสินใจเกี่ยวกับเงินเดือนได้อย่างเป็นระบบและถูกต้องมากขึ้น",
            button: "เริ่มคำนวนเงินเดือน"
        },
        post_job_social: {
            title: "สร้างโพสต์งานไปยัง Social Media (Job Post Social)",
            description: "เพิ่มโอกาสในการรับสมัครงานโดยการโพสต์งานลงบนโซเชียลมีเดียที่ได้รับความนิยม ด้วยคุณสมบัติ Job Post to Social Media ของ คุณสามารถทำการโพสต์งานบนแพลตฟอร์มโซเชียลมีเดียต่างๆ อาทิเช่น เฟซบุ๊ค ทวิตเตอร์ หรือลิงค์ลงในลิงค์ไบโอของอินสตาแกรม เพื่อให้ผู้สมัครที่ใช้โซเชียลมีเดียต่างๆ เข้าถึงข้อมูลงานที่คุณโพสต์ได้อย่างง่ายดาย",
            button: "เริ่มสร้างโพสต์งาน"
        },
        interview_question: {
            title: "สร้างคำถามในการสัมภาษณ์ (Interview Question Generator)",
            description: "สร้างคำถามสัมภาษณ์ที่น่าสนใจและเหมาะสมไม่ใช่เรื่องยากอีกต่อไป โปรแกรม Interview Questions Generator ของ จะช่วยให้คุณสร้างคำถามสัมภาษณ์ที่เป็นไปตามความต้องการของงานและสามารถทดสอบความรู้และทักษะของผู้สมัครได้อย่างมีประสิทธิภาพ และทำให้กระบวนการสัมภาษณ์เป็นไปอย่างราบรื่น",
            button: "เริ่มสร้างคำถามสัมภาษณ์งาน"
        },
    }
}




const languageConfig = 
    process.env.REACT_APP_TECHSAUCE == "true" ? th :  th ;

export default {
    // Add common config values here
    ...languageConfig
};