import { Button, Container, Nav, Navbar } from "react-bootstrap";
import "./header-landing.scss";
import LOGO_CLINIC from "../../../assets/images/main-logo-hr9.png";
import CLOSE_BLACK from "../../../assets/images/close-black.png";
import { useState } from "react";
import UtilityService from "../../../utils/utilities";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import tokenService from "../../../services/token.service";
import { getUTMParams, handleNavigation } from "../../../services/handler-route.service";
import GAConfig from "../../../ga-config";
import templateConfig from "../../../template-config";

const HeaderLandingComponent = () => {
    const [openProfile, setOpenProfile] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [openTopup, setOpenTopup] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { utmSource, utmMedium, version, feature } = getUTMParams(location);
    const handleToggle = () => {
        setExpanded(!expanded);
    };



    const clickLogin = () => {
        setExpanded(false);
        UtilityService().clickSendEvent("login_header" ,"click")
        handleNavigation(navigate, '/login', utmSource, utmMedium, feature,'');
    }

 
    const clickHome = () => {
        UtilityService().clickSendEvent("home_header" ,"click")
        navigate("/")
    }

   


    return (
        <>
            <Navbar expand="lg" expanded={expanded} className='header-landing'>
                <Container className="header-grid-control">
                    <div className="header-logo-control">
                        <img src={templateConfig.assets_env.main_logo_active} onClick={clickHome} className="header-logo-img"></img>
                    </div>

                    <div className="header-menu-center-control">
                      
                    </div>
                    <div className="header-menu-authen-control">
                    <>
                                    <Nav.Link className="header-menu-a" onClick={clickLogin}>เข้าสู่ระบบ</Nav.Link>
                                    <Button className="button-default header-button" onClick={clickLogin}>ทดลองใช้งาน</Button>
                                </>

                    </div>
                    <div className="header-menu-nav-mobile-control">
                        <Navbar.Toggle aria-controls="navbar" onClick={handleToggle} />

                    </div>

                </Container>


            </Navbar>
            {
                expanded && <div className="header-navbar-side" >
                    <div className="header-navbar-side-logo-control">
                        <img src={LOGO_CLINIC} className="header-navbar-side-logo-img"></img>
                        <img src={CLOSE_BLACK} className="header-navbar-side-close-img" onClick={handleToggle}></img>
                    </div>

                     <div className="header-navbar-divider"></div>
                    
                    <>
                                <Nav.Link className="header-navbar-a" onClick={clickLogin}>เข้าสู่ระบบ / สมัครสมาชิก</Nav.Link>
                                <Button className="button-default-blue" onClick={clickLogin}>ทดลองใช้งาน ฟรี</Button>
                            </>

                </div>
            }
        </>

    );
}

export default HeaderLandingComponent;