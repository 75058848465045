import { useEffect, useState } from "react"
import ProfileSCModel from "../../../../../model/profile-sc.model"
import "./campaign-list.scss";
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import CRMServices from "../../../../../services/crm.service";
import { Broadcast, CampaignListModel } from "../../../../../model/campaign/campaign-list.model";
import CreateCampaignComponent from "../create-campaign";
import CampaignDetailComponent from "../detail/campaign-detail";
import templateConfig from "../../../../../template-config";
import { StyledTableCell, StyledTableRow } from "../../../../../utils/theme";
var moment = require('moment-timezone');
const CampaignListComponent = ({ profileModel }: any) => {

    const [profileMod, setProfileMod] = useState<ProfileSCModel>(profileModel);
    const [campaignList, setCampaignList] = useState<CampaignListModel>();
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
    const [idEdit, setIdEdit] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [idDetail, setIdDetail] = useState("");
    
    useEffect(() => {
        if (profileMod) {
            getCampaignAll();
        }
    }, [profileMod])

    const handleCloseModalCreate = () => {
        setIsOpenCreateModal(false);
        setIsEdit(false);
        setIdEdit("");
        getCampaignAll();
    }

    const createCampaignModal = () => {
        setIsOpenCreateModal(true);
    }

    const getCampaignAll = () => {
        if (profileMod) {
            CRMServices().getCampaignAll().then((res: CampaignListModel) => {
                console.log(res);
                if (res.broadcasts) {
                    setCampaignList(res)
                }
            })
        }


    }
    const convertToUTC7 = (dateTime) => {

        console.log(dateTime);
        // Parse the input date and time string into a Moment.js object
        const inputDateTime = moment.utc(dateTime, "YYYY-MM-DD HH:mm:ss");

        // Convert the Moment.js object to UTC+7 (Asia/Bangkok) time zone
        const dateTimeInUTC7 = inputDateTime.tz("Asia/Bangkok").format("YYYY-MM-DD HH:mm:ss");
        console.log(dateTimeInUTC7);
        return dateTimeInUTC7;
    };


    const clickView = (id) => {
        if (campaignList) {
            setIsShowDetail(true);
            setIdDetail(id);
        }
    }

    const handleCloseDetail = () => {
        setIsShowDetail(false);
    }
    return (
        <>
            <div className="campaign-list-control">
                <Button className="campaign-list-button-create" onClick={createCampaignModal}>+ สร้าง Campaign</Button>

                {
                    (campaignList && campaignList.broadcasts.length > 0) ?
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell width='35%' align="left">Name</StyledTableCell>
                                        <StyledTableCell width='25%' align="left">ID</StyledTableCell>
                                        <StyledTableCell width='25%' align="left">Created at</StyledTableCell>
                                        <StyledTableCell width='15%' align="left"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaignList.broadcasts.sort((a, b) => Number(new Date(b.created_at)) - Number(new Date(a.created_at))).map((row: Broadcast, index: number) => (
                                        <StyledTableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell align="left" className="campaign-list-value-row">
                                                {row.broadcast_name}
                                            </StyledTableCell>
                                            <StyledTableCell  className="campaign-list-value-row" align="left">{row.broadcast_uuid}</StyledTableCell>
                                            <StyledTableCell  className="campaign-list-value-row" align="left">{new Date(row.created_at).toLocaleString()}</StyledTableCell>
                                            <StyledTableCell  className="campaign-list-value-row" align="left">
                                                <Button className="campaign-button-view" onClick={() => clickView(row.broadcast_uuid)}>View</Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : <div className="campaign-list-loading-control">
                            <div className="campaign-list-loading-progress-control">
                                <CircularProgress className="campaign-list-loading-progress"></CircularProgress>
                                <span className="campaign-list-loading-text">กำลังโหลด</span>
                            </div>
                        </div>
                }
                {
                    isOpenCreateModal &&
                    <CreateCampaignComponent
                        isOpen={isOpenCreateModal} isEdit={isEdit} id={idEdit} profileModel={profileMod}
                        handleClose={handleCloseModalCreate}></CreateCampaignComponent>
                }

                {
                    isShowDetail &&
                    <CampaignDetailComponent isOpen={handleCloseDetail} handleClose={handleCloseDetail} id={idDetail}></CampaignDetailComponent>
                }
            </div>
        </>
    )


}

export default CampaignListComponent;